import { ButtonHTMLAttributes } from 'react'

import { cn } from '@/utils'
import { Icons } from '@/assets'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  labelClassName?: string
  label?: string
  loading?: boolean
  disabled?: boolean
}

export const Button = ({ className, labelClassName, label, loading, disabled, ...props }: Props) => {
  return (
    <button
      className={cn(
        "block w-full bg-primary rounded-full",
        className
      )}
      disabled={disabled}
      {...props}
    >
      <div className="h-10 overflow-hidden">
        <div
          className={cn(
            "flex flex-col h-[200%] transition-all duration-100 ease-in",
            loading && "-translate-y-1/2"
          )}
        >
          <div className="flex justify-center items-center basis-1/2">
            <span
              className={cn(
                "font-outfit font-semi-bold text-lg text-white leading-none",
                labelClassName,
                disabled && "text-white/30"
              )}
            >
              {label}
            </span>
          </div>
          <div className="flex justify-center items-center basis-1/2">
            <div
              className={cn(
                "font-outfit font-semi-bold text-lg text-white",
                disabled && "text-white/30"
              )}
            >
              <img
                className="h-6"
                src={Icons.loader}
                alt="loader"
              />
            </div>
          </div>
        </div>
      </div>
    </button>
  )
}
