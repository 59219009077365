import { useNavigate } from 'react-router-dom'

import { Icons, Images } from '@/assets'
import { Profile } from '@/stores'
import { cn } from '@/utils'

type Props = {
  user: Profile | null
  collab?: Profile
}

export const MemberCard = ({ user, collab }: Props) => {
  const navigate = useNavigate()
  return (
    <div
      className={cn(
        'bg-primary rounded-3xl p-[0.4rem] cursor-pointer',
        collab && "bg-[#7a5020]"
      )}
      onClick={() => navigate(collab ? '/collect-stamps' : '/profile')}
    >
      <div className="flex justify-between items-center p-[0.4rem] mb-1">
        <div className="flex items-center gap-2">
          <div className="flex justify-center items-center bg-white border border-black/10 rounded-lg p-1 aspect-square">
            <img
              className="inline-block h-5 w-5"
              src={Images.madcoco_logo_2}
              alt="icon"
            />
          </div>
          <div className="font-outfit font-bold text-lg text-white text-left capitalize line-clamp-1">
            {user?.name?.toLowerCase()}
          </div>
        </div>
        <div className="rounded-lg bg-white p-2 font-outfit font-bold text-xs shadow-md border-2 border-black/10">
          {user?.card_type?.name?.toUpperCase()}
        </div>
      </div>
      <div className="bg-white rounded-[1.2rem] p-2">
        <div className="flex items-center gap-1">
          <div className="inline-flex justify-center items-center bg-white border border-black/10 rounded-lg p-1">
            <img
              className="inline-block h-6"
              src={collab ? Images.hogwarts : Icons.star}
              alt="icon"
            />
          </div>
          <div className="flex gap-1">
            <div
              className={cn(
                "font-outfit font-bold text-xl text-primary",
                collab && "text-[#7a5020]"
              )}
            >
              {collab ? collab?.point?.redeem : user?.point?.redeem}
            </div>
            <div className="font-outfit font-semi-bold text-sm text-black/70">
              {collab ? 'Stamps' : 'Points'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
