import { action, computed, thunk } from 'easy-peasy'
import { AxiosError } from 'axios'

import { AuthStatus, FormStatus } from '@/types'
import { encriptCode, getToken, removeToken, setCollabToken, setHeaderAuth, setHeaderCollab, setToken } from '@/utils'
import { CLIENT_CODE_COLLAB } from '@/constants'

import { AuthModel, service } from '.'

export const auth: AuthModel = {
  status: FormStatus.IDLE,
  auth: AuthStatus.UNAUTHENTICATED,
  collab: false,
  error: '',

  /** Computed */
  isAuthenticated: computed(state => state.auth === AuthStatus.AUTHENTICATED),

  /** Action */
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setAuth: action((state, payload) => {
    state.auth = payload
  }),
  setCollab: action((state, payload) => {
    state.collab = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  /** Thunks */
  login: thunk(async (action, payload) => {
    try {
      action.setStatus(FormStatus.LOADING)
      const res = await service.login(payload)
      const { data } = res
      action.loggedIn(data.api_token)
      action.setStatus(FormStatus.SUCCESS)
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setAuth(AuthStatus.UNAUTHENTICATED)
        action.setStatus(FormStatus.ERROR)
      }
    } finally {
      setTimeout(() => {
        action.setStatus(FormStatus.IDLE)
        action.setError('')
      }, 1000)
    }
  }),
  checkAuth: thunk(async (action) => {
    action.setAuth(AuthStatus.LOADING)
    const accessToken = await getToken()
    if (!accessToken) action.setAuth(AuthStatus.UNAUTHENTICATED)
    else action.loggedIn(accessToken)
  }),
  loggedIn: thunk(async (action, payload) => {
    await Promise.all([
      setToken(payload),
      setHeaderAuth(payload),
      action.loginCollab()
    ])
    action.setAuth(AuthStatus.AUTHENTICATED)
  }),
  loggedOut: thunk(async (action, callback) => {
    await Promise.all([removeToken()])
    setHeaderAuth('')
    action.setAuth(AuthStatus.UNAUTHENTICATED)
    callback()
  }),
  loginCollab: thunk(async (action) => {
    try {
      const code = encriptCode(CLIENT_CODE_COLLAB)
      const res = await service.loginCollab(code)
      const { data } = res
      setCollabToken(data.api_token)
      setHeaderCollab(data.api_token)
      action.setCollab(true)
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FormStatus.ERROR)
      }
    } finally {
      setTimeout(() => {
        action.setStatus(FormStatus.IDLE)
        action.setError('')
      }, 1000)
    }
  })
}
