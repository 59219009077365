export const production = true

const PROD = 'https://connect-platform-api.loyal.id/api/v1/'
const DEV = 'https://retail-dev.loyal.id/api/v1/'

export const BASE_URL = production ? PROD : DEV
export const CLIENT_CODE = '070801'
export const CLIENT_CODE_COLLAB = production ? '070803' : '070802'
export const SECRET_CODE = '6LcMGjEUAAAAAH_TT2w_wfBCP9tYzuA3csPzp2wN'
export const PUBLIC_KEY = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcLQ5r7MMZdIPAfLA2kylP2E1lR3lxos3R1y9Qk6VgaHV3nd18hydlPgxUHtW4gJZAg1Bd/deLBaocD5ga1AcyHsyXPkDCrD7zPT4fg26TBA5ZtvVQ5tAh3U6P2njHZ0PckHzyFMFGUMI8L9+6pQ+5qDI3A2eGoONTxbxMIDhH4wIDAQAB'
