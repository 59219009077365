import { createBrowserRouter, Navigate } from 'react-router-dom'

import { BenefitCard, ChangePassword, CollectStamp, ContactUs, EditProfile, ForgotPassword, History, Home, Login, Otp, Outlet, OutletDetail, Profile, Promo, PromoDetail, QuestRewards, QuestRewardsDetail, Register, Reward, RewardsCatalogue, RewardsCatalogueDetail } from '@/pages'
import { App } from '@/components'

import { ProtectedRoute } from './protected_route'

export const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <Navigate to="/" replace={true} />,
    element: <App />,
    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        )
      },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'register/otp', element: <Otp /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      {
        path: 'benefit-card',
        element: (
          <ProtectedRoute>
            <BenefitCard />
          </ProtectedRoute>
        )
      },
      {
        path: 'collect-stamps',
        element: (
          <ProtectedRoute>
            <CollectStamp />
          </ProtectedRoute>
        )
      },
      {
        path: 'contact-us',
        element: (
          <ProtectedRoute>
            <ContactUs />
          </ProtectedRoute>
        )
      },
      {
        path: 'reward',
        element: (
          <ProtectedRoute>
            <Reward />
          </ProtectedRoute>
        )
      },
      {
        path: 'history',
        element: (
          <ProtectedRoute>
            <History />
          </ProtectedRoute>
        )
      },
      {
        path: 'outlet',
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        )
      },
      {
        path: 'outlet/:id/detail',
        element: (
          <ProtectedRoute>
            <OutletDetail />
          </ProtectedRoute>
        )
      },
      {
        path: 'profile',
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        )
      },
      {
        path: 'profile/edit',
        element: (
          <ProtectedRoute>
            <EditProfile />
          </ProtectedRoute>
        )
      },
      {
        path: 'profile/change-password',
        element: (
          <ProtectedRoute>
            <ChangePassword />
          </ProtectedRoute>
        )
      },
      {
        path: 'promotion',
        element: (
          <ProtectedRoute>
            <Promo />
          </ProtectedRoute>
        )
      },
      {
        path: 'promotion/:id/detail',
        element: (
          <ProtectedRoute>
            <PromoDetail />
          </ProtectedRoute>
        )
      },
      {
        path: 'rewards-catalogue',
        element: (
          <ProtectedRoute>
            <RewardsCatalogue />
          </ProtectedRoute>
        )
      },
      {
        path: 'rewards-catalogue/:id/detail',
        element: (
          <ProtectedRoute>
            <RewardsCatalogueDetail />
          </ProtectedRoute>
        )
      },
      {
        path: 'quest-rewards',
        element: (
          <ProtectedRoute>
            <QuestRewards />
          </ProtectedRoute>
        )
      },
      {
        path: 'quest-rewards/:id/detail',
        element: (
          <ProtectedRoute>
            <QuestRewardsDetail />
          </ProtectedRoute>
        )
      },
      {
        path: 'quest-rewards/:id/detail',
        element: (
          <ProtectedRoute>
            <QuestRewardsDetail />
          </ProtectedRoute>
        )
      },
    ]
  }
])
