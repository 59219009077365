import { InputHTMLAttributes } from 'react'

import { cn } from '@/utils'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: string
  icon?: string
  error?: string
  onCancel?: () => void
  onReset?: () => void
}

export const InputText = ({ className, label, icon, error, onCancel, onReset, ...props }: Props) => {
  return (
    <div className={cn("relative", className)}>
      {label &&
        <div className="font-outfit font-medium text-base text-black/80 text-left mb-1">
          {label}
        </div>
      }
      <div className="relative">
        <input
          className={cn(
            "w-full bg-white rounded-lg border border-[#ececfb] outline-none p-1.5",
            "focus:bg-[#f9f9f9] focus:border-primary focus:ring-1 focus:ring-primary",
            "font-outfit font-semi-bold text-base text-black/70 placeholder:font-medium",
            error && "ring-red-600 border-red-400",
            onCancel && "pr-16"
          )}
          type="text"
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          spellCheck="false"
          {...props}
        />
        {onCancel &&
          <div className="absolute top-0 right-0 h-full p-1">
            <div
              className={cn(
                "h-full flex justify-center items-center bg-primary/90 border-2 border-primary/50 aspect-square rounded-md px-2 cursor-pointer",
                "font-outfit font-bold text-xs text-white text-center"
              )}
              onClick={onCancel}
            >
              Cancel
            </div>
          </div>
        }
        {onReset && props.value &&
          <div className="absolute top-0 right-0 h-full p-1">
            <div
              className={cn(
                "h-full flex justify-center items-center bg-primary/90 border-2 border-primary/50 aspect-square rounded-md px-2 cursor-pointer",
                "font-outfit font-bold text-xs text-white text-center"
              )}
              onClick={onReset}
            >
              RESET
            </div>
          </div>
        }
      </div>
      {error &&
        <div className="font-outfit font-medium text-xs text-red-600 mt-1">
          * {error}
        </div>
      }
    </div>
  )
}
