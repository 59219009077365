import { action, computed, thunk } from 'easy-peasy'
import { AxiosError } from 'axios'

import { FormStatus } from '@/types'

import { VoucherModel, service } from '.'

export const voucher: VoucherModel = {
  data: null,
  status: FormStatus.IDLE,
  error: '',

  /** Computed */
  loading: computed(state => state.status === FormStatus.LOADING),
  success: computed(state => state.status === FormStatus.SUCCESS),

  /** Action */
  setData: action((state, payload) => {
    state.data = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  /** Thunks */
  submit: thunk(async (action, payload) => {
    try {
      action.setStatus(FormStatus.LOADING)
      const res = await service.submit(payload)
      action.setData(res.data)
      action.setStatus(FormStatus.SUCCESS)
      action.setError('')
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FormStatus.ERROR)
      }
    } finally {
      setTimeout(() => {
        action.setStatus(FormStatus.IDLE)
      }, 10)
    }
  })
}
