import { useNavigate } from 'react-router-dom'

import { useAuth } from '@/routes/use_auth'
import { Background, Button, MemberCard, Topbar } from '@/components'
import { cn } from '@/utils'
import { Icons, Images } from '@/assets'
import { Profile as ProfileType } from '@/stores'

interface Card {
  name: string
  image: any
}

export const Profile = () => {
  const navigate = useNavigate()

  const { user, logout } = useAuth()

  const cardsList = [
    { name: 'White', image: Images.card_white },
    { name: 'Pink', image: Images.card_pink },
    { name: 'Green', image: Images.card_green },
    { name: 'Gold', image: Images.card_green },
    { name: 'Platinum', image: Images.card_platinum }
  ]

  const getCardImage = (profile: ProfileType | null, cardsList: Card[]) => {
    const card: Card | undefined = cardsList.find(
      (card) =>
        card.name?.toLowerCase() === profile?.card_type?.name?.toLowerCase()
    )
    return card ? card.image : cardsList[0].image
  }

  return (
    <div>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <Topbar />
        <div className="flex-1">
          <div className="bg-primary-light rounded-br-[2rem] rounded-bl-[2rem] border-b border-b-white p-2">
            <div className="flex justify-center items-center p-2 pb-6">
              <div
                className="inline-block w-1/2 cursor-pointer"
                onClick={() => navigate('/benefit-card')}
              >
                <img
                  className="w-full rounded-xl overflow-hidden shadow-lg"
                  src={user?.card_type?.image || getCardImage(user, cardsList)}
                  alt="card"
                />
              </div>
            </div>
            <MemberCard user={user} />
          </div>
          <div className="p-4">
            <div className="rounded-xl border border-black/20 bg-white bg-opacity-50 p-3">
              <div className="font-outfit font-semi-bold text-base text-black mb-2">Settings</div>
              <div
                className="flex justify-between items-center p-1 cursor-pointer"
                onClick={() => navigate('edit')}
              >
                <div className="font-outfit font-regular text-base text-black">
                  Edit Profile
                </div>
                <img
                  className="h-5"
                  src={Icons.right_arrow}
                  alt="icon"
                />
              </div>
              <div
                className="flex justify-between items-center p-1 cursor-pointer"
                onClick={() => navigate('change-password')}
              >
                <div className="font-outfit font-regular text-base text-black">
                  Change Password
                </div>
                <img
                  className="h-5"
                  src={Icons.right_arrow}
                  alt="icon"
                />
              </div>
              <div className="font-outfit font-semi-bold text-base text-black my-2">Support</div>
              <div
                className="flex justify-between items-center p-1 cursor-pointer"
                onClick={() => navigate('/contact-us')}
              >
                <div className="font-outfit font-regular text-base text-black">
                  Contact Us
                </div>
                <img
                  className="h-5"
                  src={Icons.right_arrow}
                  alt="icon"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <Button
            label="Logout"
            onClick={logout}
          />
          <div className="flex flex-col justify-center items-center mt-4">
            <div className="font-outfit font-semi-bold text-xs text-black/60 text-center mt-3">
              Powered by
            </div>
            <img
              className="inline-block h-8 mt-2"
              src={Images.loyalid_blue}
              alt="logo"
            />
          </div>
        </div>
      </div>
    </div>
  )
}