import { HistoryModel, history } from './history'
import { ProductModel, product } from './product'
import { ProfileModel, profile } from './profile'
import { RedeemModel, redeem } from './redeem'
import { VoucherModel, voucher } from './voucher'

export interface CollabModel {
  history: HistoryModel
  product: ProductModel
  profile: ProfileModel
  redeem: RedeemModel
  voucher: VoucherModel
}

export const collab: CollabModel = {
  history,
  product,
  profile,
  redeem,
  voucher
}
