import { InputHTMLAttributes, useRef, useState } from 'react'

import { cn } from '@/utils'
import { Icons } from '@/assets'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: string
  icon?: string
  error?: string
}

export const InputDate = ({ className, label, icon, error, ...props }: Props) => {
  const [focus, setFocus] = useState<boolean>(false)

  const ref = useRef<HTMLInputElement>(null)

  return (
    <div className={cn("relative", className)}>
      {label &&
        <div className="font-outfit font-medium text-base text-black/80 text-left mb-1">
          {label}
        </div>
      }
      <div className="relative">
        <input
          ref={ref}
          className={cn(
            "w-full bg-white rounded-lg border border-[#ececfb] outline-none p-1.5",
            "focus:bg-[#f9f9f9] focus:border-primary focus:ring-1 focus:ring-primary",
            "font-outfit font-semi-bold text-base text-black/70 placeholder:font-medium",
            error && "ring-red-600 border-red-400"
          )}
          type={focus ? "date" : "text"}
          onTouchStart={() => setFocus(true)}
          onFocus={() => setFocus(true)}
          onBlur={() => !ref.current?.value && setFocus(false)}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          spellCheck="false"
          {...props}
        />
      </div>
      {error &&
        <div className="font-outfit font-medium text-xs text-red-600 mt-1">
          * {error}
        </div>
      }
    </div>
  )
}
