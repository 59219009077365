import { Fragment } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'

import { Button } from '@/components'
import { cn } from '@/utils'

interface Props {
  title: string
  content: string
  onConfirm: () => void
  onCancel: () => void
}

export const Confirm = ({ title, content, onConfirm, onCancel }: Props) => {

  const { pathname } = useLocation()
  const collab = pathname.includes('quest') || pathname.includes('stamp')

  return (
    <Fragment>
      {createPortal(
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[999999]">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-mobile w-full p-4">
            <div className="bg-white p-3 rounded-xl shadow-xl">
              <div className="font-outfit font-semi-bold text-base">
                {title}
              </div>
              <div className="font-outfit font-regular text-sm mt-2">
                {content}
              </div>
              <div className="flex gap-1 mt-2">
                <Button
                  className={cn(
                    "block rounded-lg p-0",
                    collab && "bg-[#7a5020]"
                  )}
                  label="Yes"
                  onClick={onConfirm}
                />
                <Button
                  className="block rounded-lg p-0 bg-white border border-[#ddd]"
                  labelClassName="text-black/70"
                  label="Cancel"
                  onClick={onCancel}
                />
              </div>
            </div>
          </div>
        </div>,
        document.body
      )}
    </Fragment>
  )
}
