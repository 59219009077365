import { useRef, useState } from 'react'
import Sticky from 'react-sticky-el'
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import { QRFunc } from 'react-qrbtf'

import { Background, Topbar } from '@/components'
import { cn } from '@/utils'
import { Unused, Used } from './components'
import { Voucher } from '@/stores'
import { Icons } from '@/assets'

export const Reward = () => {
  const [type, setType] = useState<'available' | 'used'>('available')
  const [filter, setFilter] = useState<'all' | 'point' | 'stamp'>('all')
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [showBarcode, setShowBarcode] = useState<boolean>(false)
  const [voucher, setVoucher] = useState<Voucher>()
  const [collab, setCollab] = useState<boolean>(false)

  const sheetRef = useRef<BottomSheetRef>(null)

  return (
    <div>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <Topbar />
        <div className="flex flex-col flex-1 p-5">
          <div className="flex justify-between items-center">
            <div className="font-outfit font-bold text-xl text-primary text-left">
              My Rewards
            </div>
            <div className="relative flex items-center gap-1 bg-white">
              <div
                className="flex items-center border border-black/20 rounded-lg p-0.5 cursor-pointer"
                onClick={() => setShowFilter(!showFilter)}
              >
                <div className="font-outfit font-semi-bold text-sm text-black/80 text-center w-14 capitalize">
                  {filter}
                </div>
                <img
                  className="h-4"
                  src={Icons.select}
                  alt="icon"
                />
              </div>
              <div
                className={cn(
                  "absolute top-full mt-1 right-0 w-full py-2 rounded-xl bg-white border border-black/20 shadow-lg z-10",
                  "transition-all duration-300 opacity-0 invisible translate-y-4",
                  showFilter && "opacity-100 visible translate-y-0"
                )}
              >
                <div
                  className={cn(
                    "font-outfit font-medium text-sm px-2 py-1 hover:bg-[#f5f5f5] cursor-pointer",
                    filter === 'all' && "bg-primary text-white hover:bg-primary"
                  )}
                  onClick={() => {
                    setShowFilter(false)
                    setFilter('all')
                  }}
                >
                  All
                </div>
                <div
                  className={cn(
                    "font-outfit font-medium text-sm px-2 py-1 hover:bg-[#f5f5f5] cursor-pointer",
                    filter === 'point' && "bg-primary text-white hover:bg-primary"
                  )}
                  onClick={() => {
                    setShowFilter(false)
                    setFilter('point')
                  }}
                >
                  Point
                </div>
                <div
                  className={cn(
                    "font-outfit font-medium text-sm px-2 py-1 hover:bg-[#f5f5f5] cursor-pointer",
                    filter === 'stamp' && "bg-primary text-white hover:bg-primary"
                  )}
                  onClick={() => {
                    setShowFilter(false)
                    setFilter('stamp')
                  }}
                >
                  Stamp
                </div>
              </div>
            </div>
          </div>
          <Sticky stickyClassName="!top-[60px] z-20 animate-show-sticky">
            <div className="relative flex justify-between items-center border border-black/20 bg-white rounded-lg mt-3 overflow-hidden">
              <div
                className={cn(
                  "absolute top-0 left-0 w-1/2 h-full bg-primary",
                  "transition-all duration-300",
                  type === 'used' && "translate-x-full"
                )}
              />
              <div
                className={cn(
                  "relative basis-1/2 p-2 font-outfit font-semi-bold text-black/60 text-center z-10",
                  "transition-all duration-300",
                  type === 'available' && "text-white"
                )}
                onClick={() => setType('available')}
              >
                Available
              </div>
              <div
                className={cn(
                  "relative basis-1/2 p-2 font-outfit font-semi-bold text-black/60 text-center z-10",
                  "transition-all duration-300",
                  type === 'used' && "text-white"
                )}
                onClick={() => setType('used')}
              >
                Used
              </div>
            </div>
          </Sticky>
          <div className="flex flex-col flex-1">
            {type === 'available' ?
              <Unused
                showBarcode={(data, collab = false) => {
                  setVoucher(data)
                  setCollab(collab)
                  setShowBarcode(true)
                }}
                filter={filter}
              />
              :
              <Used
                showBarcode={(data, collab = false) => {
                  setVoucher(data)
                  setCollab(collab)
                  setShowBarcode(true)
                }}
                filter={filter}
              />
            }
          </div>
        </div>
      </div>
      <BottomSheet
        open={showBarcode}
        ref={sheetRef}
        sibling={
          <div
            data-rsbs-backdrop="true"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setShowBarcode(false)
            }}
          />
        }
        blocking={false}
        onDismiss={() => setShowBarcode(false)}
        style={{ width: 100 }}
      >
        <div
          className={cn(
            "flex flex-col justify-center items-center py-5 bg-primary",
            collab && "bg-[#7a5020]"
          )}
        >
          <div className="h-60 border border-black/20 rounded-3xl bg-white">
            <QRFunc
              value={voucher?.serial_no}
              type="round"
              posType="round"
            />
          </div>
          <div className="font-outfit font-bold text-xl text-center text-white mt-2">
            {voucher?.serial_no}
          </div>
        </div>
      </BottomSheet>
    </div>
  )
}