import { api, client_code } from '@/utils'

import { Params, Response, ResponseDetail, Service } from '.'

const fetch = async (params: Params): Promise<Response> => {
  try {
    const res = await api.get<Response>('redeem/product', { params: { ...params, client_code } })
    return res.data
  } catch (e) {
    throw e
  }
}

const fetchDetail = async (id: number): Promise<ResponseDetail> => {
  try {
    const res = await api.get<ResponseDetail>(`redeem/product/${id}`, { params: { client_code } })
    return res.data
  } catch (e) {
    throw e
  }
}

export const service: Service = {
  fetch,
  fetchDetail
}
