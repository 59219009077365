import { Fragment, useEffect } from 'react'

import { Background, Topbar } from '@/components'
import { useStoreActions, useStoreState } from '@/stores'
import { CLIENT_CODE } from '@/constants'
import { cn } from '@/utils'
import { Icons } from '@/assets'

export const ContactUs = () => {
  const { fetch } = useStoreActions(action => action.client)
  const { data } = useStoreState(state => state.client)

  useEffect(() => {
    fetch()
  }, [fetch])

  const CONTACT_NUMBER = data.find(client => client.code === CLIENT_CODE)?.whatsapp_number?.replace(/[-+ ]/g, '') || ''
  const IG = data.find(client => client.code === CLIENT_CODE)?.instagram_link || ''

  return (
    <Fragment>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <Topbar />
        <div className="flex-1 p-5">
          <div className="font-outfit font-bold text-xl text-primary">
            Contact Us
          </div>
          <div className="flex flex-col gap-2 mt-4">
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => window.open(`https://wa.me/${CONTACT_NUMBER}`)}
            >
              <img
                className="h-8"
                src={Icons.whatsapp}
                alt="icon"
              />
              <div className="font-outfit font-semi-bold text-base text-black/80">
                Customer Service
              </div>
            </div>
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => window.open(IG)}
            >
              <img
                className="h-8"
                src={Icons.instagram}
                alt="icon"
              />
              <div className="font-outfit font-semi-bold text-base text-black/80">
                instagram.com/madcoco.id
              </div>
            </div>
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => window.open('https://www.tiktok.com/@madcoco.id')}
            >
              <img
                className="h-8"
                src={Icons.tiktok}
                alt="icon"
              />
              <div className="font-outfit font-semi-bold text-base text-black/80">
                tiktok.com/@madcoco.id
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
