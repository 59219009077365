import forge from 'node-forge'

import { PUBLIC_KEY } from '@/constants'

export const encriptCode = (code: string) => {
  const pki = forge.pki
  const pem = '-----BEGIN PUBLIC KEY-----\n' + PUBLIC_KEY + '-----END PUBLIC KEY-----'
  const publicKey = pki.publicKeyFromPem(pem)
  const encrypted = publicKey.encrypt(code, 'RSA-OAEP', {
    md: forge.md.sha1.create(),
    mgf: forge.mgf.mgf1.create(forge.md.sha1.create())
  })

  return forge.util.encode64(encrypted)
}