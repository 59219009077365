import { api } from '@/utils'
import { CLIENT_CODE, production } from '@/constants'

import { Response, Service } from '.'

const fetch = async (): Promise<Response> => {
  try {
    const res = await api.get<Response>(`client-group/${production ? '06' : CLIENT_CODE.slice(0, 2)}/client`)
    return res.data
  } catch (e) {
    throw e
  }
}

export const service: Service = {
  fetch
}
