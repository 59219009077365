import { api, client_code } from '@/utils'

import { Params, Response, Service } from '.'

const submit = async (params: Params): Promise<Response> => {
  try {
    const body = { ...params, client_code }
    const res = await api.post<Response>(`redeem/voucher?client_code=${client_code}`, body)
    return res.data
  } catch (e) {
    throw e
  }
}

export const service: Service = {
  submit
}
