import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import Sticky from 'react-sticky-el'
import axios from 'axios'

import { useAuth } from '@/routes/use_auth'
import { Background, Fab, InputText, MemberCard, Welcome } from '@/components'
import { Location } from '@/types'
import { cn, getGeolocation, getWelcomeGift, removeGeolocation, removeWelcomeGift, setGeolocation } from '@/utils'

import { Feature, Outlet, Promo, Stamp } from './components'
import { Icons, Images } from '@/assets'
import { useStoreActions, useStoreState } from '@/stores'
import { CLIENT_CODE } from '@/constants'

export const Home = () => {
  const navigate = useNavigate()

  const { user } = useAuth()

  const { fetch } = useStoreActions(action => action.client)
  const { data } = useStoreState(state => state.client)

  const [fixed, setFixed] = useState<boolean>(false)

  const [welcome, setWelcome] = useState<boolean>(false)
  const [location, setLocation] = useState<Location>()
  const [latitude, setLatitude] = useState<number>(0)
  const [longitude, setLongitude] = useState<number>(0)

  const [address, setAddress] = useState<string>('')
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [changeLocation, setChangeLocation] = useState<boolean>(false)
  const [currentLocation, setCurrentLocation] = useState<string>('')
  const [inputLocation, setInputLocation] = useState<string>('')

  useEffect(() => {
    fetch()
  }, [fetch])

  const CONTACT_NUMBER = data.find(client => client.code === CLIENT_CODE)?.whatsapp_number?.replace(/[-+ ]/g, '') || ''
  const IG = data.find(client => client.code === CLIENT_CODE)?.instagram_link || ''

  const getCityName = async (latitude: number, longitude: number) => {
    try {
      setSearchLoading(true)
      const res = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=id`)
      setLocation(res.data)
      setSearchLoading(false)
    } catch (e) {
      console.log(e)
      setSearchLoading(false)
    }
  };

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          getCityName(latitude, longitude)
          setLatitude(latitude)
          setLongitude(longitude)
          setChangeLocation(false)
        },
        (error) => {
          console.error('Error get location: ', error)
        }
      )
    } else {
      console.log('Geolocation is not supported by this browser')
    }
  }, [])

  useEffect(() => {
    const currentLocation = async () => {
      const location = await getGeolocation()
      if (location) {
        const item = JSON.parse(location)
        setLatitude(item.latitude)
        setLongitude(item.longitude)
        setInputLocation(item.address)
      } else {
        getLocation()
      }
    }
    currentLocation()
  }, [getLocation])

  useEffect(() => {
    setCurrentLocation([
      location?.address?.village,
      location?.address?.suburb,
      location?.address?.municipality,
      location?.address?.county,
      location?.address?.state,
      location?.address?.neighbourhood,
      location?.address?.city_district,
    ].filter((item) => item)?.join(', '))
  }, [location])

  const getWelcome = async () => {
    const welcome = await getWelcomeGift()
    if (welcome) setWelcome(true)
  }

  useEffect(() => {
    getWelcome()
  }, [])

  return (
    <div>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <div className="z-50">
          <Sticky onFixedToggle={setFixed}>
            <div
              className={cn(
                "bg-primary-light p-2",
                fixed && "shadow-md"
              )}
            >
              <div className="flex justify-between items-center">
                <div className="p-2">
                  <img
                    className="inline-block h-6"
                    src={Images.madcoco_logo_1}
                    alt="logo"
                  />
                </div>
                <div
                  className="p-2 cursor-pointer"
                  onClick={() => navigate('/profile')}
                >
                  <img
                    className="inline-block h-7"
                    src={Icons.setting_profile}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </Sticky>
          <div className="bg-primary-light rounded-br-[2rem] rounded-bl-[2rem] border-b border-b-white p-2">
            {changeLocation ?
              <div className="relative mb-2 px-1">
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={(address) => {
                    geocodeByAddress(address)
                      .then((results) => getLatLng(results[0]))
                      .then((coords) => {
                        setLatitude(coords.lat)
                        setLongitude(coords.lng)
                        setInputLocation(address)
                        setChangeLocation(false)
                        setAddress('')
                        const location = {
                          address,
                          latitude: coords.lat,
                          longitude: coords.lng
                        }
                        setGeolocation(JSON.stringify(location))
                      })
                      .catch(error => console.error(error))
                  }}
                  searchOptions={{ componentRestrictions: { country: ['id'] } }}
                >
                  {({
                    getInputProps,
                    getSuggestionItemProps,
                    suggestions,
                    loading
                  }) => (
                    <Fragment>
                      <InputText
                        {...getInputProps({
                          placeholder: "Search location ...",
                        })}
                        icon={Icons.search_location}
                        onCancel={() => setChangeLocation(false)}
                        autoFocus
                      />
                      {address && suggestions.length > 0 &&
                        <div className="absolute top-11 left-0 w-full bg-white rounded-xl border border-black/10 py-2 transition-all duration-300 shadow-xl z-10">
                          {suggestions.map((suggestion, index) =>
                            <div
                              {...getSuggestionItemProps(suggestion)}
                              key={index}
                              className={cn(
                                "px-3 py-2 font-outfit font-medium text-sm text-black/80 cursor-pointer",
                                "hover:bg-primary hover:text-white",
                                index < suggestions.length - 1 && "border-b border-b-gray-100"
                              )}
                            >
                              {suggestion.description}
                            </div>
                          )}
                        </div>
                      }
                    </Fragment>
                  )}
                </PlacesAutocomplete>
              </div>
              :
              <div className="flex justify-between items-center gap-1 mb-2 px-1">
                <div className="flex flex-1 items-center gap-1.5">
                  <div
                    className="border border-[#7BA388] rounded-lg p-1 shrink-0"
                    onClick={() => {
                      setInputLocation('')
                      removeGeolocation()
                      getLocation()
                      setChangeLocation(false)
                    }}
                  >
                    <img
                      className="block h-3"
                      src={Icons.location}
                      alt="icon"
                    />
                  </div>
                  <div className="font-outfit font-medium text-sm text-primary line-clamp-1">
                    {inputLocation || currentLocation}
                  </div>
                </div>
                <div>
                  <div
                    className="font-outfit font-bold text-sm text-primary cursor-pointer"
                    onClick={() => setChangeLocation(true)}
                  >
                    Change
                  </div>
                </div>
              </div>
            }
            <MemberCard user={user} />
          </div>
        </div>
        <div className="flex flex-col gap-2 p-2">
          <Feature />
          <Stamp />
          <Promo currentLocation={{ latitude, longitude }} />
          <Outlet currentLocation={{ latitude, longitude }} />
          <div className="font-outfit font-bold text-xs text-primary text-center mt-4">
            Contact Us
          </div>
          <div className="flex justify-center items-center gap-2 mt-2">
            <div
              onClick={() => window.open(`https://wa.me/${CONTACT_NUMBER}`)}
            >
              <img
                className="h-10"
                src={Icons.whatsapp}
                alt="icon"
              />
            </div>
            <div
              onClick={() => window.open(IG)}
            >
              <img
                className="h-10"
                src={Icons.instagram}
                alt="icon"
              />
            </div>
            <div
              onClick={() => window.open('https://www.tiktok.com/@madcoco.id')}
            >
              <img
                className="h-10"
                src={Icons.tiktok}
                alt="icon"
              />
            </div>
          </div>
          <div className="flex justify-center items-center mt-2 mb-5">
            <img
              className="h-5"
              src={Images.madcoco}
              alt="logo"
            />
          </div>
        </div>
      </div>
      <Fab onClick={() => navigate('/collect-stamps')} />
      {welcome &&
        <Welcome
          title="Welcome to MADCOCO"
          content={
            <div className="flex flex-col justify-center items-center my-4">
              <img
                className="h-40"
                src={Images.madcoco_head_text}
                alt="logo"
              />
            </div>
          }
          onClose={() => {
            removeWelcomeGift()
            navigate('/history')
          }}
        />
      }
    </div>
  )
}