import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '@/routes/use_auth'
import { Background, MemberCard, Topbar } from '@/components'
import { RedeemProduct, useStoreActions, useStoreState } from '@/stores'
import { cn } from '@/utils'
import { Icons, Images } from '@/assets'

export const RewardsCatalogue = () => {
  const navigate = useNavigate()

  const { user } = useAuth()

  const { data, loading } = useStoreState(state => state.redeem.product)
  const { fetch } = useStoreActions(action => action.redeem.product)

  const [sort, setSort] = useState<'asc' | 'desc'>('asc')

  useEffect(() => {
    fetch({
      page: 1,
      limit: 100,
      type: 'all',
      status: 'available',
      source: 'all'
    })
  }, [fetch])

  const Image = ({ item }: { item: RedeemProduct }) => {
    const [error, setError] = useState<boolean>(false)

    if (error || !item?.images?.[0])
      return (
        <div
          className="flex justify-center items-center aspect-square bg-[#f5f5f5] animate-fade"
        >
          <img
            className="h-10 opacity-20"
            src={Icons.image_break}
            alt="icon"
          />
        </div>
      )

    return (
      <div
        className="aspect-square bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${item?.images?.[0]})` }}
      >
        <img
          className="hidden"
          src={item?.images?.[0]}
          onError={() => setError(true)}
          alt="outlet"
        />
      </div>
    )
  }

  return (
    <div>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <Topbar />
        <div className="bg-primary-light rounded-br-[2rem] rounded-bl-[2rem] border-b border-b-white p-2">
          <MemberCard user={user} />
        </div>
        <div className="flex flex-col flex-1 p-2 pt-0">
          <div className="flex justify-between items-center mx-1 mt-3">
            <div />
            <div className="flex items-center gap-1 bg-white">
              <div className="font-outfit font-medium text-sm text-black/80 mt-1">
                Sort
              </div>
              <div
                className="flex items-center border border-black/20 rounded-lg p-0.5 cursor-pointer"
                onClick={() => setSort(sort === 'asc' ? 'desc' : 'asc')}
              >
                <img
                  className="h-4"
                  src={sort === 'asc' ? Icons.sort_descending : Icons.sort_ascending}
                  alt="icon"
                />
                <div className="font-outfit font-semi-bold text-sm text-black/80 text-center w-32">
                  {sort === 'asc' ? 'Lowest to Highest' : 'Highest to Lowest'}
                </div>
              </div>
            </div>
          </div>
          {loading ?
            <div className="flex flex-1 justify-center items-center">
              <div className="font-outfit font-semi-bold text-base">
                <div className="animate-loader">
                  <img
                    className="h-28"
                    src={Images.madcoco_head}
                    alt="logo"
                  />
                  <div className="font-outfit font-semi-bold text-base text-center text-primary mt-2">
                    Loading...
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="grid grid-cols-2 gap-3 p-1 mt-2">
              {data?.filter(item => !item.name.includes('Harry Potter'))?.sort((a, b) => sort === 'asc' ? a.point - b.point : b.point - a.point)?.map((item, index) =>
                <div
                  key={index}
                  className="opacity-0 inline-grid animate-show"
                  style={{ animationDelay: `${index * .1}s` }}
                >
                  <div
                    className="flex flex-col rounded-xl border border-black/20 overflow-hidden cursor-pointer"
                    onClick={() => navigate(`${item?.id}/detail`, { state: { item } })}
                  >
                    <Image item={item} />
                    <div className="flex-1 flex flex-col px-2 py-3">
                      <div className="flex-1 font-outfit font-semi-bold text-xs text-black/70 leading-[1.2] line-clamp-2">
                        {item?.name}
                      </div>
                      <div className="font-outfit font-bold text-sm text-primary mt-1">
                        {item?.point} Points
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
