import { Fragment, useCallback, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useAuth } from '@/routes/use_auth'
import { useStoreActions } from '@/stores'
import { Background, Button, InputText, Topbar } from '@/components'
import { Icons } from '@/assets'
import { cn, setWelcomeGift } from '@/utils'

import { useRegisterNew, useSendVerificationCode } from './hooks'

export const Otp = () => {
  const location = useLocation()

  const data = location?.state?.data

  const { login, isLoggedIn } = useAuth()

  const { setTitle, setMessage, present } = useStoreActions(action => action.message)

  const [timeLeft, setTimeLeft] = useState<number>(60)

  const { submit: send } = useSendVerificationCode({
    onSuccess: (message) => {
      console.log(message)
    },
    onError: (message: string) => {
      setTitle('ERROR')
      setMessage(message)
      present()
    }
  })

  const { loading, submit } = useRegisterNew({
    onSuccess: (message, data) => {
      console.log(message)
      setWelcomeGift('true')
      login(data.mobile_phone, data.password)
    },
    onError: (message: string) => {
      setTitle('ERROR')
      setMessage(message)
      present()
    }
  })

  const sendCode = useCallback(() => {
    if (data?.mobile_phone) {
      send(data.mobile_phone)
      setTimeLeft(60)
    }
  }, [data?.mobile_phone, send])

  useEffect(() => {
    sendCode()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (timeLeft === 0) setTimeLeft(0)
    if (!timeLeft) return

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  const schema = yup.object().shape({
    code: yup.string().required('Verification code is required')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      code: ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: schema,
    onSubmit: values => submit({ ...data, phone_verification_code: values.code })
  })

  console.log('DATA', data)

  if (isLoggedIn())
    return <Navigate to="/" replace={true} />

  return (
    <Fragment>
      <Background />
      <form onSubmit={handleSubmit}>
        <div
          className={cn(
            "flex flex-col bg-white bg-opacity-30 min-h-screen",
            "min-h-dvh"
          )}
        >
          <Topbar />
          <div className="flex-1 p-5">
            <div className="font-outfit font-bold text-xl text-black/80">
              Create Account
            </div>
            <div className="font-outfit font-regular text-base text-black/80 mt-2">
              Please enter the OTP code we sent to <span className="font-semi-bold">+{data?.mobile_phone?.slice(0, -4)}****</span>
            </div>
            <div className="flex flex-col gap-4 mt-4">
              <InputText
                name="code"
                placeholder=""
                icon={Icons.key}
                value={values.code}
                onChange={handleChange}
                error={errors.code}
              />
              {timeLeft === 0 ?
                <div
                  className="font-outfit font-bold text-sm text-black/80 text-center cursor-pointer"
                  onClick={() => sendCode()}
                >
                  Send New Code
                </div>
                :
                <div className="font-outfit font-bold text-xs text-black/80 text-center">
                  Having trouble? Request a new code in 00:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}
                </div>
              }
            </div>
          </div>
          <div className="p-5">
            <Button
              type="submit"
              label="Send"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </Fragment>
  )
}
