import axios from 'axios'

import { BASE_URL, CLIENT_CODE } from '@/constants'
import store from '@/stores'

export const client_code = CLIENT_CODE

export const api = axios.create({
  baseURL: BASE_URL
})

export const collab = axios.create({
  baseURL: BASE_URL
})

export const setHeaderAuth = (jwt: string): void => {
  api.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
  api.interceptors.response.use(response => {
    return response
  }, error => {
    if (error?.response?.status === 401) {
      store.dispatch.auth.loggedOut(() => console.log('Logout: Unautorized'))
    }
    return Promise.reject(error)
  })
}

export const setHeaderCollab = (jwt: string): void => {
  collab.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
  collab.interceptors.response.use(response => {
    return response
  }, error => {
    if (error?.response?.status === 401) {
      store.dispatch.profile.fetch()
    }
    return Promise.reject(error)
  })
}
