import { UnusedModel, unused } from './unused'
import { UsedModel, used } from './used'

export interface VoucherModel {
  unused: UnusedModel
  used: UsedModel
}

export const voucher: VoucherModel = {
  unused,
  used
}
