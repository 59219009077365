import { Fragment } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useAuth } from '@/routes/use_auth'
import { useStoreActions } from '@/stores'
import { Background, Button, Checkbox, InputDate, InputPassword, InputPhone, InputText, Topbar } from '@/components'
import { Icons } from '@/assets'
import { cn } from '@/utils'

import { useRegisterValidate } from './hooks'

export const Register = () => {
  const navigate = useNavigate()

  const { isLoggedIn } = useAuth()

  const { setTitle, setMessage, present } = useStoreActions(action => action.message)

  const { loading, submit } = useRegisterValidate({
    onSuccess: (message, data) => {
      console.log(message)
      navigate('otp', { state: { data } })
    },
    onError: (message) => {
      setTitle('ERROR')
      setMessage(message)
      present()
    }
  })

  const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    mobile_phone: yup.string().required('Phone number is required'),
    gender: yup.string().required('Gender is required'),
    birth_date: yup.string().required('Birth date is required'),
    password: yup
      .string()
      .required('New password is required')
      .matches(/^(?=[^A-Za-z]*[A-Za-z])(?=.*\d).{8,}$/, 'The password must be at least 8 characters, a combination of letters and numbers'),
    confirm_password: yup
      .string()
      .required('Confirm new password is required')
      .matches(/^(?=[^A-Za-z]*[A-Za-z])(?=.*\d).{8,}$/, 'The password must be at least 8 characters, a combination of letters and numbers')
      .oneOf([yup.ref('password')], 'Passwords must match')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      name: '',
      mobile_phone: '',
      gender: '',
      birth_date: '',
      password: '',
      confirm_password: ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: schema,
    onSubmit: values => {
      submit({ ...values })
    }
  })

  if (isLoggedIn())
    return <Navigate to="/" replace={true} />

  return (
    <Fragment>
      <Background />
      <form onSubmit={handleSubmit}>
        <div
          className={cn(
            "flex flex-col bg-white bg-opacity-30 min-h-screen",
            "min-h-dvh"
          )}
        >
          <Topbar />
          <div className="flex-1 p-5">
            <div className="font-outfit font-bold text-xl text-primary text-left">
              Create Account
            </div>
            <div className="flex flex-col gap-4 mt-4">
              <InputText
                name="name"
                label="Name"
                icon={Icons.user}
                value={values.name}
                onChange={handleChange}
                error={errors.name}
              />
              <InputPhone
                name="mobile_phone"
                label="Phone number"
                value={values.mobile_phone}
                onChange={e => handleChange('mobile_phone')(e.target.value.replace(/[+-\s]/g, ''))}
                error={errors.mobile_phone}
              />
              <InputDate
                name="birth_date"
                label="Birth Date"
                value={values.birth_date}
                onChange={handleChange}
                error={errors.birth_date}
              />
              <div className="flex gap-2 p-1">
                <div className="flex basis-1/2 items-center gap-2">
                  <Checkbox
                    id="male"
                    defaultChecked={values.gender === 'M'}
                    checked={values.gender === 'M'}
                    onClick={() => handleChange('gender')('M')}
                  />
                  <label
                    className={cn(
                      "font-outfit font-medium text-base text-black/80 leading-none",
                      values.gender === 'M' && "text-black/80 font-semi-bold"
                    )}
                    htmlFor="male"
                  >
                    Male
                  </label>
                </div>
                <div className="flex basis-1/2 items-center gap-2">
                  <Checkbox
                    id="female"
                    defaultChecked={values.gender === 'F'}
                    checked={values.gender === 'F'}
                    onClick={() => handleChange('gender')('F')}
                  />
                  <label
                    className={cn(
                      "font-outfit font-medium text-base text-black/80 leading-none",
                      values.gender === 'F' && "text-black/80 font-semi-bold"
                    )}
                    htmlFor="female"
                  >
                    Female
                  </label>
                </div>
              </div>
              {errors.gender &&
                <div className="font-outfit font-medium text-xs text-red-600 -mt-3">
                  * {errors.gender}
                </div>
              }
              <InputPassword
                name="password"
                label="Password"
                value={values.password}
                onChange={handleChange}
                error={errors.password}
              />
              <InputPassword
                name="confirm_password"
                label="Confirm password"
                icon={Icons.lock_check}
                value={values.confirm_password}
                onChange={handleChange}
                error={errors.confirm_password}
              />
            </div>
          </div>
          <div className="p-5">
            <Button
              type="submit"
              label="Register"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </Fragment>
  )
}