import { useState } from 'react'
import { AxiosError } from 'axios'

import { api } from '@/utils'
import { useStoreActions } from '@/stores'

export interface Props {
  onSuccess: (message: string) => void,
  onError: (message: string) => void
}

export interface Params {
  profile_picture?: File | null
  name: string
  mobile_phone: string
  gender: string
  birth_date: string
}

export const useEditProfile = ({ onSuccess, onError }: Props) => {
  const [loading, setLoading] = useState(false)
  const { fetch } = useStoreActions((action) => action.profile)

  const submit = async (params: Params): Promise<void> => {
    try {
      setLoading(true)

      const formData = new FormData()
      for (const key in params) {
        const name = key as keyof Params
        if (params[name] && key !== 'mobile_phone') formData.append(key, params[name])
      }

      const res = await api.post('customer',
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: formData => formData
      })

      onSuccess(res?.data?.message)
      fetch()
    } catch (e) {
      if (e instanceof AxiosError) {
        onError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
      }
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 1)
    }
  }

  return { loading, submit }
}
