import { useEffect, useState } from 'react'

import { api } from '@/utils'
import { CLIENT_CODE } from '@/constants'
import { FetchStatus } from '@/types'

export type Response = {
  status: string
  status_code: number
  message: string
  data: UseBenefitCard[]
}

type UseBenefitCard = {
  id: number
  name: string
  target_amount: number
  minimum_expense: number
  multiplier_point: number
  expired_period: number
  level_up_evaluation_days: number
  level_down_evaluation_days: number
  default: boolean
  active: boolean
  image: string
  benefits: Benefit[]
  level_down_to: Level | null
  level_up_to: Level | null
}

type Benefit = {
  type: string
  title: string
  value: string
}

type Level = {
  id: number
  name: string
  target_amount: number
  minimum_expense: number
  multiplier_point: number
  expired_period: number
  level_up_evaluation_days: number
  level_down_evaluation_days: number
  default: false
  active: true
  image: string
}

export const useBenefitCard = () => {
  const [data, setData] = useState<UseBenefitCard[]>([])
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.IDLE)

  const fetchBenefitCard = async () => {
    setStatus(FetchStatus.LOADING)
    try {
      const res = await api.get<Response>(
        `master/card-type?client_code=${CLIENT_CODE}`,
      )
      const { data } = res.data
      setData(data)
      setStatus(FetchStatus.LOADED)
    } catch (e: any) {
      setStatus(FetchStatus.ERROR)
    }
  }

  useEffect(() => {
    fetchBenefitCard()
  }, [])

  return { status, data }
}
