import { Fragment, useRef } from 'react'
import { createPortal } from 'react-dom'

import { Images } from '@/assets'
import { cn } from '@/utils'

interface Props {
  onClick: () => void
}

export const Fab = ({ onClick }: Props) => {

  let offsetX: number
  let offsetY: number
  let isMove: boolean

  const ref = useRef<HTMLDivElement>(null)
  const overlay = useRef<HTMLDivElement>(null)

  const move = (e: MouseEvent) => {
    const el = e.target as HTMLDivElement
    el.style.left = `${e.pageX - offsetX}px`
    el.style.top = `${e.pageY - offsetY}px`
    isMove = true
    overlay.current!.style!.backgroundColor = 'rgba(0,0,0,.5)'
  }

  const add = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    const el = e.target as HTMLDivElement
    offsetX = e.clientX - el.getBoundingClientRect().left + window.scrollX
    offsetY = e.clientY - el.getBoundingClientRect().top + window.scrollY

    el.addEventListener('mousemove', move)
  }

  const remove = (e: React.MouseEvent<HTMLElement>): void => {
    const el = e.target as HTMLDivElement
    snap()
    el.removeEventListener('mousemove', move)
  }

  const moveTouch = (e: TouchEvent) => {
    e.preventDefault()
    const el = e.target as HTMLDivElement
    el.style.left = `${e.touches[0].pageX - offsetX}px`
    el.style.top = `${e.touches[0].pageY - offsetY}px`
    overlay.current!.style!.backgroundColor = 'rgba(0,0,0,.5)'
  }

  const addTouch = (e: React.TouchEvent<HTMLElement>): void => {
    e.preventDefault()
    const el = e.target as HTMLDivElement
    offsetX = e.touches[0].clientX - el.getBoundingClientRect().left + window.scrollX
    offsetY = e.touches[0].clientY - el.getBoundingClientRect().top + window.scrollY

    el.addEventListener('touchmove', moveTouch)
  }

  const removeTouch = (e: React.TouchEvent<HTMLElement>): void => {
    const el = e.target as HTMLDivElement
    snap()
    el.removeEventListener('touchmove', moveTouch)
  }

  const snap = () => {
    if (ref.current) {
      const snapTo = ((ref.current?.offsetLeft + (ref.current?.offsetWidth / 2)) < window.innerWidth / 2) ? 'left' : 'right'
      const top = ref.current?.offsetTop
      ref.current!.style!.transition = 'all .5s ease'
      if (snapTo === 'right') ref.current!.style!.left = `${window.innerWidth - ref.current?.offsetWidth - 16}px`
      else ref.current!.style!.left = '16px'
      if (top < 16) ref.current!.style!.top = '16px'
      if (top + ref.current?.offsetHeight > (window.innerHeight - 80)) ref.current!.style!.top = `${window.innerHeight - ref.current?.offsetHeight - 80}px`
      setTimeout(() => ref.current!.style!.transition = 'none', 500)
    }
    setTimeout(() => isMove = false, 500)
    overlay.current!.style!.backgroundColor = 'unset'
  }
  return (
    <Fragment>
      {createPortal(
        <div
          ref={overlay}
          className="fixed top-0 left-0 w-full h-full z-[999999] pointer-events-none"
        >
          <div
            ref={ref}
            className="fixed top-[calc(100%_-_9rem)] right-4 w-24 h-24 flex items-center pointer-events-auto cursor-pointer"
            onMouseDown={add}
            onMouseUp={remove}
            onMouseLeave={remove}
            onTouchStart={addTouch}
            onTouchEnd={removeTouch}
            onClick={() => !isMove && setTimeout(() => onClick(), 500)}
          >
            <img
              className="h-full"
              src={Images.fab}
              alt="logo"
            />
          </div>
        </div>,
        document.body
      )}
    </Fragment>
  )
}
