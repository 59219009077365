const userKey = 'madcoco:user_token'
const collabKey = 'madcoco:collab_token'
const passKey = 'madcoco:user_pass'
const welcomeGift = 'madcoco:welcome_gift'
const geolocation = 'madcoco:geolocation'

export const setToken = async (token: string): Promise<void> => {
  return localStorage.setItem(userKey, token)
}

export const getToken = async (): Promise<string | null> => {
  return localStorage.getItem(userKey)
}

export const removeToken = async (): Promise<void> => {
  return localStorage.removeItem(userKey)
}

export const setCollabToken = async (token: string): Promise<void> => {
  return localStorage.setItem(collabKey, token)
}

export const getCollabToken = async (): Promise<string | null> => {
  return localStorage.getItem(collabKey)
}

export const removeCollabToken = async (): Promise<void> => {
  return localStorage.removeItem(collabKey)
}

export const setUserPass = async (data: string): Promise<void> => {
  return localStorage.setItem(passKey, data)
}

export const getUserPass = async (): Promise<string | null> => {
  return localStorage.getItem(passKey)
}

export const removeUserPass = async (): Promise<void> => {
  return localStorage.removeItem(passKey)
}

export const setWelcomeGift = async (data: string): Promise<void> => {
  return localStorage.setItem(welcomeGift, data)
}

export const getWelcomeGift = async (): Promise<string | null> => {
  return localStorage.getItem(welcomeGift)
}

export const removeWelcomeGift = async (): Promise<void> => {
  return localStorage.removeItem(welcomeGift)
}

export const setGeolocation = async (data: string): Promise<void> => {
  return localStorage.setItem(geolocation, data)
}

export const getGeolocation = async (): Promise<string | null> => {
  return localStorage.getItem(geolocation)
}


export const removeGeolocation = async (): Promise<void> => {
  return localStorage.removeItem(geolocation)
}
