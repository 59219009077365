import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Background, Topbar } from '@/components'
import { Outlet, useStoreActions, useStoreState } from '@/stores'
import { Icons } from '@/assets'
import { cn } from '@/utils'

export const OutletDetail = () => {
  const { id } = useParams()
  const location = useLocation()

  const item: Outlet = location?.state?.item

  const { detail } = useStoreState(state => state.outlet)
  const { fetchDetail } = useStoreActions(action => action.outlet)

  useEffect(() => {
    if (!item) fetchDetail(Number(id))
  }, [item, id, fetchDetail])

  const Image = ({ item }: { item: Outlet }) => {
    const [error, setError] = useState<boolean>(false)

    if (error || !item?.image)
      return (
        <div
          className="flex justify-center items-center aspect-square bg-[#f5f5f5] animate-fade"
        >
          <img
            className="h-10 opacity-20"
            src={Icons.image_break}
            alt="icon"
          />
        </div>
      )

    return (
      <div
        className="aspect-square bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${item?.image})` }}
      >
        <img
          className="hidden"
          src={item?.image}
          onError={() => setError(true)}
          alt="outlet"
        />
      </div>
    )
  }

  const data = detail || item

  return (
    <div>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <div className="flex flex-1 flex-col">
          <Topbar />
          <div className="flex-1">
            <Image item={data} />
            <div className="p-5">
              <div className="font-outfit font-semi-bold text-xl text-black/70">
                {data?.name}
              </div>
              <div className="font-outfit font-regular text-sm text-black/70 mt-2">
                Unit: <span className="font-semibold">{data?.unit || '-'}</span>
              </div>
              <div className="font-outfit font-regular text-sm text-black/70">
                Floor: <span className="font-semibold">{data?.floor || '-'}</span>
              </div>
              <div className="font-outfit font-regular text-sm text-black/70 mt-2">
                {data?.note}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
