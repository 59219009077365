import { useNavigate } from 'react-router-dom'

import { Icons, Images } from '@/assets'

export const Feature = () => {
  const navigate = useNavigate()

  return (
    <div className="flex bg-white/50 border border-black/20 rounded-3xl p-1 shadow-sm">
      <div className="basis-1/4 shrink-0 p-[3px] box-border">
        <div
          className="flex flex-col gap-1 justify-center items-center aspect-square bg-[#fff6f6] bg-opacity-50 border border-[#ffe7e7] rounded-[1.2rem] cursor-pointer"
          onClick={() => navigate('/rewards-catalogue')}
        >
          <div
            className="flex justify-center items-center w-[40%] aspect-square bg-contain bg-no-repeat bg-center"
            style={{ backgroundImage: `url(${Icons.voucher})` }}
          />
          <div className="flex justify-center items-center h-6 font-outfit font-bold text-[11px] text-center text-primary leading-none line-clamp-2">
            Rewards<br />Catalogue
          </div>
        </div>
      </div>
      <div className="basis-1/4 shrink-0 p-[3px] box-border">
        <div
          className="flex flex-col gap-1 justify-center items-center aspect-square bg-[#fff6f6] bg-opacity-50 border border-[#ffe7e7] rounded-[1.2rem] cursor-pointer"
          onClick={() => navigate('/reward')}
        >
          <div
            className="flex justify-center items-center w-[40%] aspect-square bg-contain bg-no-repeat bg-center"
            style={{ backgroundImage: `url(${Icons.gift})`, backgroundSize: '90%' }}
          />
          <div className="flex justify-center items-center h-6 font-outfit font-bold text-[11px] text-center text-primary leading-none line-clamp-2 w-full overflow-hidden">
            My<br />Rewards
          </div>
        </div>
      </div>
      <div className="basis-1/4 shrink-0 p-[3px] box-border">
        <div
          className="flex flex-col gap-1 justify-center items-center aspect-square bg-[#fff6f6] bg-opacity-50 border border-[#ffe7e7] rounded-[1.2rem] cursor-pointer"
          onClick={() => navigate('/history')}
        >
          <div
            className="flex justify-center items-center w-[40%] aspect-square bg-contain bg-no-repeat bg-center"
            style={{ backgroundImage: `url(${Icons.history})`, backgroundSize: '90%' }}
          />
          <div className="flex justify-center items-center h-6 font-outfit font-bold text-[11px] text-center text-primary leading-none line-clamp-2">
            My<br />History
          </div>
        </div>
      </div>
      <div className="basis-1/4 shrink-0 p-[3px] box-border">
        <div
          className="flex flex-col gap-1 justify-center items-center aspect-square bg-[#fff6f6] bg-opacity-50 border border-[#ffe7e7] rounded-[1.2rem] cursor-pointer"
          onClick={() => navigate('/quest-rewards')}
        >
          <div
            className="flex justify-center items-center w-[40%] aspect-square bg-contain bg-no-repeat bg-center"
            style={{ backgroundImage: `url(${Images.hogwarts})` }}
          />
          <div className="flex justify-center items-center h-6 font-outfit font-bold text-[11px] text-center text-[#7a5020] leading-none line-clamp-2">
            Quest<br />Rewards
          </div>
        </div>
      </div>
    </div>
  )
}