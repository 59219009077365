import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider, { Settings } from 'react-slick'
import Zoom from 'react-medium-image-zoom'

import { useAuth } from '@/routes/use_auth'
import { Background, Topbar } from '@/components'
import { useStoreActions, useStoreState } from '@/stores'
import { Icons, Images } from '@/assets'
import { cn } from '@/utils'

export const CollectStamp = () => {
  const navigate = useNavigate()

  const { collab } = useAuth()

  const { fetch } = useStoreActions(action => action.collab.profile)
  const { data, loaded, error } = useStoreState(state => state.collab.profile)

  const [slideIndex, setSlideIndex] = useState<number>(0)
  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    if (collab) {
      fetch()
    }
  }, [collab, fetch])

  useEffect(() => {
    if (loaded) setReady(true)
  }, [loaded])

  useEffect(() => {
    if (error) navigate(-1)
  }, [error, navigate])

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    beforeChange: (_, next) => setSlideIndex(next)
  }

  const stampIcons = [
    Icons.stamp_gryffindor,
    Icons.stamp_hufflepuff,
    Icons.stamp_ravenclaw,
    Icons.stamp_slytherin,
    Icons.stamp_hogwarts
  ]

  const stampHolders = [
    Icons.stamp_gryffindor_holder,
    Icons.stamp_hufflepuff_holder,
    Icons.stamp_ravenclaw_holder,
    Icons.stamp_slytherin_holder,
    Icons.stamp_hogwarts_holder
  ]

  const totalStamps = data?.point?.redeem || 0
  const floor = Math.floor(totalStamps / 10)
  const totalHolders = totalStamps === 0 ? 10 : (floor + (totalStamps % 10 > 0 ? 1 : 0)) * 10

  let ref = useRef<Slider>(null)

  return (
    <div>
      <Background collab />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <Topbar collab />
        <div className="flex flex-col flex-1 p-2">
          <div className="font-wizard text-xl text-center text-[#7a5020] p-4">
            enchanted quest
          </div>
          <div className="bg-[#E6B352] border border-black/20 rounded-3xl p-[0.4rem] shadow-sm">
            <div className="flex justify-between items-center px-2 py-1 mb-1">
              <div className="flex items-center gap-1.5">
                <div className="flex justify-center items-center bg-white border border-[#a8823a] shadow-sm rounded-lg p-1 aspect-square">
                  <img
                    className="inline-block h-5 w-5"
                    src={Images.hogwarts}
                    alt="icon"
                  />
                </div>
                <div className="font-outfit font-bold text-sm text-[#7a5020]">
                  STAMPS {ready && <span>({totalStamps})</span>}
                </div>
              </div>
              {(totalHolders / 10 > 1 && ready) &&
                <div className="flex bg-white rounded-full p-0.5 shadow-md animate-fade">
                  <div
                    onClick={() => ref.current?.slickPrev()}
                  >
                    <img
                      className={cn(
                        "h-5 aspect-square",
                        slideIndex === 0 && "opacity-70"
                      )}
                      src={Icons.prev}
                      alt="icon"
                    />
                  </div>
                  <div className="flex justify-center items-center font-outfit font-semi-bold text-sm px-1">
                    {(slideIndex * 10) + 1} - {(slideIndex + 1) * 10 > totalStamps ? (slideIndex * 10) + (totalStamps % 10) : (slideIndex + 1) * 10}
                  </div>
                  <div
                    onClick={() => ref.current?.slickNext()}
                  >
                    <img
                      className={cn(
                        "h-5 aspect-square",
                        slideIndex + 1 === totalHolders / 10 && "opacity-70"
                      )}
                      src={Icons.next}
                      alt="icon"
                    />
                  </div>
                </div>
              }
            </div>
            <div className="rounded-[1.2rem] bg-white border border-[#a8823a] overflow-hidden shadow-sm">
              {!ready ?
                <div className="flex flex-1 justify-center items-center">
                  <div className="font-outfit font-semi-bold text-base">
                    <div className="animate-loader p-4">
                      <img
                        className="h-28"
                        src={Images.hogwarts}
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>
                :
                <Slider
                  ref={ref}
                  {...settings}
                >
                  {[...Array(totalHolders / 10)].map((_, index: number) =>
                    <div key={index}>
                      <div className="grid grid-cols-5 gap-0.5 p-0.5 overflow-hidden">
                        {[...Array(10)].map((_, i: number) =>
                          <div
                            key={i}
                            className="relative aspect-square"
                          >
                            <div
                              className="relative w-full aspect-square bg-contain bg-center bg-no-repeat z-10"
                              style={{ backgroundImage: `url(${stampHolders[i % 5]})` }}
                            />
                            {totalStamps > (i + (index * 10)) &&
                              <Zoom>
                                <img
                                  className={cn(
                                    "absolute top-0 left-0 w-full z-20 pointer-events-auto",
                                    "opacity-0 animate-stamp"
                                  )}
                                  style={{ animationDelay: `${i * .5}s` }}
                                  src={stampIcons[i % 5]}
                                  alt="icon"
                                />
                              </Zoom>
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Slider>
              }
            </div>
          </div>
          <div
            className={cn(
              "flex flex-col gap-2 bg-white border-2 border-[#7a5020] border-dashed mt-3 rounded-2xl p-4",
              "font-outfit text-sm text-[#7a5020]"
            )}
          >
            <b>Descriptions:</b>
            <div>
              Join our enchanted journey and unlock the rewards!
            </div>
            <p>
              Earn 1 stamp for every purchase of Rp100,000 on exclusive Madcoco and
              Harry Potter collections.
            </p>
            <b>Stamp Rewards:</b>
            <ul className="list-disc pl-4">
              <li>
                Collect 2 stamps to exchange for a Rp10,000 voucher for Madcoco and Harry Potter merchandise.
              </li>
              <li>
                Collect 5 stamps to exchange for a Rp25,000 voucher for Madcoco and Harry Potter merchandise.
              </li>
              <li>
                Collect 10 stamps to exchange for a FREE Wand Wielder Keychain.
              </li>
            </ul>
            <b>Terms and conditions apply:</b>
            <ul className="list-disc pl-4">
              <li>Valid while stocks last.</li>
              <li>Cannot be combined with other promotions.</li>
              <li>Keychains are provided as mystery items, so character selection is unavailable.</li>
            </ul>
          </div>
          <div className="py-4 px-1">
            <img
              className="w-full"
              src={Images.legal_line}
              alt="legal"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
