import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getDistance } from 'geolib'
import { debounce } from 'lodash'

import { Outlet as OutletItem, useStoreActions, useStoreState } from '@/stores'
import { Background, InputText, Topbar } from '@/components'
import { cn, getGeolocation } from '@/utils'
import { Icons, Images } from '@/assets'

type CurrentLocation = {
  latitude: number
  longitude: number
}

export const Outlet = () => {
  const navigate = useNavigate()

  const { data, search, loading, loaded } = useStoreState(state => state.outlet)
  const { fetch, find } = useStoreActions(action => action.outlet)

  const [keyword, setKeyword] = useState<string>('')
  const [latitude, setLatitude] = useState<number>(0)
  const [longitude, setLongitude] = useState<number>(0)

  useEffect(() => {
    fetch({
      page: 1,
      limit: 200,
      active: 1
    })
  }, [fetch])

  const searchOutlet = (s: string) => {
    if (s) {
      find({
        page: 1,
        limit: 200,
        active: 1,
        s
      })
    }
  }

  const debouncedSearch = debounce(searchOutlet, 500)

  useEffect(() => {
    if (keyword) debouncedSearch(keyword)
  }, [keyword, debouncedSearch])

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          setLatitude(latitude)
          setLongitude(longitude)
        },
        (error) => {
          console.error('Error get location: ', error)
        }
      )
    } else {
      console.log('Geolocation is not supported by this browser')
    }
  }, [])

  useEffect(() => {
    const currentLocation = async () => {
      const location = await getGeolocation()
      if (location) {
        const item = JSON.parse(location)
        setLatitude(item.latitude)
        setLongitude(item.longitude)
      } else {
        getLocation()
      }
    }
    currentLocation()
  }, [getLocation])

  const calculateDistance = (from: OutletItem, to: CurrentLocation) => {
    if (!to) return 0
    const dis = getDistance(
      { latitude: from.latitude, longitude: from.longitude },
      { latitude: to.latitude, longitude: to.longitude }
    )
    return dis / 1000
  }

  if (latitude !== 0 && longitude !== 0) {
    data.sort((a, b) => calculateDistance(a, { latitude, longitude }) - calculateDistance(b, { latitude, longitude }))
  }

  const Image = ({ item }: { item: OutletItem }) => {
    const [error, setError] = useState<boolean>(false)

    if (error || !item?.image)
      return (
        <div
          className="flex justify-center items-center aspect-square bg-[#f5f5f5] animate-fade"
        >
          <img
            className="h-10 opacity-20"
            src={Icons.image_break}
            alt="icon"
          />
        </div>
      )

    return (
      <div
        className="aspect-square bg-center bg-cover"
        style={{ backgroundImage: `url(${item?.image})` }}
      >
        <img
          className="hidden"
          src={item?.image}
          onError={() => setError(true)}
          alt="outlet"
        />
      </div>
    )
  }

  return (
    <div>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <Topbar />
        <div className="flex flex-col flex-1 p-4">
          <div className="font-outfit font-bold text-xl text-primary text-left">
            Outlets
          </div>
          <div className="mt-3 hidden">
            <InputText
              placeholder="Search location ..."
              icon={Icons.search_location}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onReset={() => setKeyword('')}
            />
          </div>
          {search.length === 0 && loaded && keyword &&
            <div className="flex flex-col flex-1 justify-center items-center">
              <img
                className="h-12"
                src={Icons.empty}
                alt="icon"
              />
              <div className="font-outfit font-medium text-base text-primary">
                Not Found
              </div>
            </div>
          }
          {loading ?
            <div className="flex flex-1 justify-center items-center">
              <div className="font-outfit font-semi-bold text-base">
                <div className="animate-loader">
                  <img
                    className="h-28"
                    src={Images.madcoco_head}
                    alt="logo"
                  />
                  <div className="font-outfit font-semi-bold text-base text-center text-primary mt-2">
                    Loading...
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="grid grid-cols-2 gap-4 mt-4">
              {((keyword && loaded) ? search : data)?.map((item, index) =>
                <div
                  key={index}
                  className="flex flex-col shrink-0 rounded-lg bg-white bg-opacity-50 border border-[#dddddd] overflow-hidden cursor-pointer"
                  onClick={() => navigate(`/outlet/${item?.id}/detail`, { state: { item } })}
                >
                  <Image item={item} />
                  <div className="flex flex-1 flex-col justify-center items-center px-1 py-2">
                    <div className="font-outfit font-bold text-xs text-black/70 text-center leading-[1.2] line-clamp-2">
                      {item?.name}
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
