import { Outlet, useLocation } from 'react-router'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-spring-bottom-sheet/dist/style.css'

import { UserProvider } from '@/routes/use_auth'
import { Container } from '@/components'

export const App = () => {
  const { pathname } = useLocation()

  const meta = document.querySelector('meta[name="theme-color"]')
  meta?.setAttribute('content', '#eafff1')

  if (pathname.includes('quest') || pathname.includes('stamp')) {
    meta?.setAttribute('content', '#7A5020')
  } else {
    meta?.setAttribute('content', '#eafff1')
  }

  return (
    <Container>
      <UserProvider>
        <Outlet />
      </UserProvider>
    </Container>
  )
}
