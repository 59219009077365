import { api } from '@/utils'
import { CLIENT_CODE } from '@/constants'

import { Params, Response, Service } from '.'

const login = async (params: Params): Promise<Response> => {
  try {
    const body = {
      ...params,
      client_code: CLIENT_CODE
    }
    const res = await api.post<Response>('login', body)
    return res.data
  } catch (e) {
    throw e
  }
}

const loginCollab = async (code_client: string): Promise<Response> => {
  try {
    const body = {
      code_client
    }
    const res = await api.post<Response>('customer/login', body)
    return res.data
  } catch (e) {
    throw e
  }
}

export const service: Service = {
  login,
  loginCollab
}
