import { action, computed, thunk } from 'easy-peasy'
import { AxiosError } from 'axios'

import { FetchStatus } from '@/types'

import { Profile, ProfileModel, service } from '.'

export const profile: ProfileModel = {
  data: {} as Profile,
  status: FetchStatus.IDLE,
  error: '',
  unauthenticated: false,

  /** Computed */
  loading: computed(state => state.status === FetchStatus.LOADING),
  loaded: computed(state => state.status === FetchStatus.LOADED),

  /** Action */
  setData: action((state, payload) => {
    state.data = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  setUnauthenticated: action((state, payload) => {
    state.unauthenticated = payload
  }),

  /** Thunks */
  fetch: thunk(async (action) => {
    try {
      action.setUnauthenticated(false)
      action.setStatus(FetchStatus.LOADING)
      const res = await service.fetch()
      action.setData(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.ERROR)
        if (e?.response?.status === 401) action.setUnauthenticated(true)
      }
      action.setStatus(FetchStatus.ERROR)
    }
  })
}
