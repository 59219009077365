import { useState } from 'react'
import { AxiosError } from 'axios'

import { api } from '@/utils'
import { CLIENT_CODE, SECRET_CODE } from '@/constants'

interface Props {
  onSuccess: (message: string) => void,
  onError: (message: string) => void
}

// essage
// : 
// "Please try again in 13 seconds"
// status
// : 
// "error"
// status_code
// : 
// 422

export const useSendVerificationCode = ({ onSuccess, onError }: Props) => {
  const [loading, setLoading] = useState(false)

  const submit = async (mobile_phone: string) => {
    try {
      setLoading(true)
      const smsToken = await getSmsToken()
      await sendVerificationCode(mobile_phone, smsToken)
      onSuccess('Register Success')
    } catch (e) {
      if (e instanceof AxiosError) {
        onError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
      }
    } finally {
      setTimeout(() => setLoading(false), 1)
    }
  }

  return { loading, submit }
}

interface Response {
  status: string
  status_code: number
  data: {
    sms_token: string
  }
}

const getSmsToken = async (): Promise<string> => {
  try {

    const body = {
      client_code: CLIENT_CODE,
      secret_code: SECRET_CODE
    }
    const formData = new FormData()
    formData.append('client_code', CLIENT_CODE)
    formData.append('secret_code', SECRET_CODE)
    const res = await api.post<Response>('sms-token', body)
    const { data } = res.data
    return data.sms_token
  } catch (e) {
    throw e
  }
}

const sendVerificationCode = async (mobile_phone: string, smsToken: string) => {
  try {
    const body = {
      client_code: CLIENT_CODE,
      mobile_phone,
      type: 'New Registration',
      sms_token: smsToken,
      channel: 'wa'
    }
    await api.post('verification/phone/send', body)
  } catch (e) {
    throw e
  }
}
