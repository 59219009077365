import { InputHTMLAttributes } from 'react'
import InputMask from 'react-input-mask'

import { cn } from '@/utils'
import { Icons } from '@/assets'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: string
  error?: string
}

export const InputPhone = ({ className, label, error, ...props }: Props) => {
  return (
    <div className={cn("relative", className)}>
      {label &&
        <div className="font-outfit font-medium text-base text-black/80 text-left mb-1">
          {label}
        </div>
      }
      <div className="relative">
        <InputMask
          className={cn(
            "w-full bg-white rounded-lg border border-[#ececfb] outline-none p-1.5",
            "focus:bg-[#f9f9f9] focus:border-primary focus:ring-1 focus:ring-primary",
            "font-outfit font-semi-bold text-base text-black/70 placeholder:font-medium",
            error && "ring-red-600 border-red-400"
          )}
          type="tel"
          mask="+62 999-9999-9999-99"
          maskPlaceholder={null}
          beforeMaskedStateChange={({ nextState, currentState, previousState }) => {
            let { value } = nextState
            if (value.endsWith('-')) value = value.slice(0, -1)
            if (value.charAt(4) === '0') value = `${value.slice(0, 4)}${value.slice(5)}`
            if (value.slice(4, 6) === '62') value = `${value.slice(0, 4)}${value.slice(6)}`
            if (currentState?.value?.startsWith('0')) {
              const phone = `+62${currentState?.value.slice(1)}`
              value = `${phone.slice(0, 3)} ${phone.slice(3, 6)}-${phone.slice(6, 10)}-${phone.slice(10, 14)}${phone.length > 14 ? `-${phone.slice(14, 16)}` : ''}`
              nextState.selection!.start = value.length
              nextState.selection!.end = value.length
            }
            if (currentState?.value?.slice(0, 2) === '62') {
              const phone = `+${currentState?.value}`
              value = `${phone.slice(0, 3)} ${phone.slice(3, 6)}-${phone.slice(6, 10)}-${phone.slice(10, 14)}${phone.length > 14 ? `-${phone.slice(14, 16)}` : ''}`
              nextState.selection!.start = value.length
              nextState.selection!.end = value.length
            }

            return {
              ...nextState,
              value
            }
          }}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          spellCheck="false"
          {...props}
        />
      </div>
      {error &&
        <div className="font-outfit font-medium text-xs text-red-600 mt-1">
          * {error}
        </div>
      }
    </div>
  )
}
