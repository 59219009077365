import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import 'react-medium-image-zoom/dist/styles.css'

import stores from '@/stores'

import reportWebVitals from './reportWebVitals'
import { router } from './routes/router'

import './index.css'

if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StoreProvider store={stores}>
    <RouterProvider router={router} />
  </StoreProvider>
)

reportWebVitals()
