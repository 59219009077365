import { api, client_code } from '@/utils'

import { Params, Response, Service } from '.'

const fetch = async (params: Params): Promise<Response> => {
  try {
    const res = await api.get<Response>('event', { params: { ...params, client_code } })
    return res.data
  } catch (e) {
    throw e
  }
}

export const service: Service = {
  fetch
}
