import { Fragment, ReactNode } from 'react'
import { createPortal } from 'react-dom'

import { Button } from '@/components'

interface Props {
  title: string
  content: ReactNode
  onClose: () => void
}

export const Welcome = ({ title, content, onClose }: Props) => {
  return (
    <Fragment>
      {createPortal(
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[999999]">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-mobile w-full p-4">
            <div className="bg-white p-3 rounded-3xl shadow-xl">
              <div className="font-outfit font-semi-bold text-base text-center">
                {title}
              </div>
              <div>
                {content}
              </div>
              <div className="mt-2">
                <Button
                  className="block"
                  labelClassName="text-sm"
                  label="Click to get ur welcome gift"
                  onClick={onClose}
                />
              </div>
            </div>
          </div>
        </div>,
        document.body
      )}
    </Fragment>
  )
}
