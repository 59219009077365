import { useState } from 'react'
import { AxiosError } from 'axios'

import { CLIENT_CODE } from '@/constants'
import { api } from '@/utils'

export interface Props {
  onSuccess: (message: string, params: Params) => void,
  onError: (message: string) => void
}

export type Params = {
  name: string
  mobile_phone: string
  gender: string
  birth_date: string
  password: string
}

export const useRegisterValidate = ({ onSuccess, onError }: Props) => {
  const [loading, setLoading] = useState(false)

  const submit = async (params: Params) => {
    try {
      setLoading(true)
      const body = {
        client_code: CLIENT_CODE,
        ...params
      }
      const res = await api.post('register/new/validate', body)
      onSuccess(res.data.message, params)
    } catch (e) {
      if (e instanceof AxiosError) {
        onError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
      }
    } finally {
      setTimeout(() => setLoading(false), 1)
    }
  }

  return { loading, submit }
}
