import { useState } from 'react'
import { AxiosError } from 'axios'

import { CLIENT_CODE } from '@/constants'
import { api } from '@/utils'

interface Props {
  onSuccess: (message: string, params: Params) => void,
  onError: (message: string) => void
}

type Params = {
  name: string
  mobile_phone: string
  gender: string
  birth_date: string
  password: string
  phone_verification_code: string
}

type Response = {
  status: string
  status_code: number
  message: string
  data: {
    id: number
    code: string
    name: string
    // id_number: string
    profile_picture: string | null
    active: boolean
    total_expense: number
    point: {
      redeem: number
      lucky_draw: number
    }
    api_token: string
  }
}

export const useRegisterNew = ({ onSuccess, onError }: Props) => {
  const [loading, setLoading] = useState(false)

  const submit = async (params: Params) => {
    try {
      setLoading(true)
      const body = {
        client_code: CLIENT_CODE,
        ...params
      }
      const res = await api.post<Response>('register/new', body)
      const { data } = res.data
      onSuccess(data.api_token, params)
    } catch (e: any) {
      if (e instanceof AxiosError) {
        onError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
      }
    } finally {
      setTimeout(() => setLoading(false), 1)
    }
  }

  return { loading, submit }
}
