import { collab } from '@/utils'
import { CLIENT_CODE_COLLAB } from '@/constants'

import { Params, Response, Service } from '.'

const submit = async (params: Params): Promise<Response> => {
  try {
    const body = { ...params }
    const res = await collab.post<Response>(`redeem/voucher?client_code=${CLIENT_CODE_COLLAB}`, body)
    return res.data
  } catch (e) {
    throw e
  }
}

export const service: Service = {
  submit
}
