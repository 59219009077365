import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Background, Button, Confirm, Topbar } from '@/components'
import { useStoreActions, useStoreState } from '@/stores'
import { RedeemProduct } from '@/stores'
import { cn } from '@/utils'
import { Icons } from '@/assets'

export const RewardsCatalogueDetail = () => {
  const { id } = useParams()
  const location = useLocation()

  const item: RedeemProduct = location?.state?.item

  const { fetch } = useStoreActions(action => action.profile)

  const { detail } = useStoreState(state => state.redeem.product)
  const { fetchDetail } = useStoreActions(action => action.redeem.product)

  const { loading, success } = useStoreState(state => state.redeem.voucher)
  const { submit } = useStoreActions(action => action.redeem.voucher)

  const { setTitle, setMessage, present } = useStoreActions(action => action.message)

  const [confirm, setConfirm] = useState<boolean>(false)

  useEffect(() => {
    if (!item) fetchDetail(Number(id))
  }, [item, id, fetchDetail])

  const redeem = () => {
    setConfirm(false)
    console.log('REEDED', item)
    if (item?.source?.toLowerCase() === 'internal') {
      const data = {
        products: [{ id: item?.id, qty: 1 }]
      }
      submit(data)
    }
  }

  useEffect(() => {
    if (success) {
      setTitle('Redeem')
      setMessage('Congratulations! You\'ve got the voucher!\nCheck it out in my reward menu and use it ASAP before it expires!')
      present()
      fetch()
    }
  }, [success, fetch, present, setMessage, setTitle])

  const Image = ({ item }: { item: RedeemProduct }) => {
    const [error, setError] = useState<boolean>(false)

    if (error || !item?.images?.[0])
      return (
        <div
          className="flex justify-center items-center aspect-square bg-[#f5f5f5] animate-fade"
        >
          <img
            className="h-10 opacity-20"
            src={Icons.image_break}
            alt="icon"
          />
        </div>
      )

    return (
      <div
        className="aspect-square bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${item?.images?.[0]})` }}
      >
        <img
          className="hidden"
          src={item?.images?.[0]}
          onError={() => setError(true)}
          alt="outlet"
        />
      </div>
    )
  }

  const data = detail || item

  return (
    <div>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <div className="flex flex-1 flex-col">
          <Topbar />
          <div className="flex-1">
            <Image item={data} />
            <div className="p-4">
              <div className="font-outfit font-semi-bold text-sm text-black/70">
                {data?.name}
              </div>
              <div className="font-outfit font-bold text-lg text-primary">
                {data?.point} Points
              </div>
              <div className="font-outfit font-medium text-sm text-black/70 mt-2">
                Available at: {data?.available_stores?.length > 0 ? data?.available_stores?.map(item => item.store_name).join(', ') : 'All Outlets'}
              </div>
              {data?.note &&
                <div className="font-outfit font-medium text-sm text-black/70 mt-2"
                  dangerouslySetInnerHTML={{ __html: data?.note }}
                />
              }
              {data?.instructions &&
                <div className="font-outfit font-medium text-sm text-black/70 mt-2"
                  dangerouslySetInnerHTML={{ __html: data?.instructions }}
                />
              }
              {data?.terms &&
                <div className="font-outfit font-medium text-sm text-black/70 mt-2"
                  dangerouslySetInnerHTML={{ __html: data?.terms }}
                />
              }
            </div>
          </div>
          <div className="p-4">
            <Button
              label="Redeem"
              onClick={() => setConfirm(true)}
              loading={loading}
            />
          </div>
        </div>
      </div>
      {confirm &&
        <Confirm
          title="Are you sure to redeem your point?"
          content=""
          onConfirm={redeem}
          onCancel={() => setConfirm(false)}
        />
      }
    </div>
  )
}
