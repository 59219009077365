import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getDistance } from 'geolib'

import { Promo as PromoType, useStoreActions, useStoreState } from '@/stores'
import { Background, InputText, Topbar } from '@/components'
import { cn, getGeolocation } from '@/utils'
import { Icons } from '@/assets'

type CurrentLocation = {
  latitude: number
  longitude: number
}

export const Promo = () => {
  const navigate = useNavigate()

  const { data: outlet } = useStoreState(state => state.outlet)
  const { fetch: getOutlet } = useStoreActions(action => action.outlet)

  const { data } = useStoreState(state => state.promo)
  const { fetch } = useStoreActions(action => action.promo)

  const [latitude, setLatitude] = useState<number>(0)
  const [longitude, setLongitude] = useState<number>(0)
  const [filter, setFilter] = useState<number | null>(null)
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')

  useEffect(() => {
    getOutlet({
      page: 1,
      limit: 200,
      active: 1
    })
  }, [getOutlet])

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          setLatitude(latitude)
          setLongitude(longitude)
        },
        (error) => {
          console.error('Error get location: ', error)
        }
      )
    } else {
      console.log('Geolocation is not supported by this browser')
    }
  }, [])

  useEffect(() => {
    const currentLocation = async () => {
      const location = await getGeolocation()
      if (location) {
        const item = JSON.parse(location)
        setLatitude(item.latitude)
        setLongitude(item.longitude)
      } else {
        getLocation()
      }
    }
    currentLocation()
  }, [getLocation])

  const calculateDistance = (from: PromoType['store'], to: CurrentLocation) => {
    if (!to) return 0
    const dis = getDistance(
      { latitude: from.latitude, longitude: from.longitude },
      { latitude: to.latitude, longitude: to.longitude }
    )
    return dis / 1000
  }

  if (latitude !== 0 && longitude !== 0) {
    outlet.sort((a, b) => calculateDistance(a, { latitude, longitude }) - calculateDistance(b, { latitude, longitude }))
    data.sort((a, b) => calculateDistance(a.store, { latitude, longitude }) - calculateDistance(b.store, { latitude, longitude }))
  }

  useEffect(() => {
    fetch({
      page: 1,
      limit: 100,
      status: 'running'
    })
  }, [fetch])

  const Image = ({ item }: { item: PromoType }) => {
    const [error, setError] = useState<boolean>(false)

    if (error || !item?.photos?.[0])
      return (
        <div
          className="flex justify-center items-center aspect-square bg-[#f5f5f5] animate-fade"
        >
          <img
            className="h-10 opacity-20"
            src={Icons.image_break}
            alt="icon"
          />
        </div>
      )

    return (
      <div
        className="aspect-square bg-center bg-cover"
        style={{ backgroundImage: `url(${item?.photos?.[0]})` }}
      >
        <img
          className="hidden"
          src={item?.photos?.[0]}
          onError={() => setError(true)}
          alt="outlet"
        />
      </div>
    )
  }

  return (
    <div>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <Topbar />
        <div className="flex flex-col flex-1 p-2">
          <div className="font-outfit font-bold text-xl text-primary text-left px-2">
            Promotion
          </div>
          <div className="flex flex-col gap-4 mt-3">
            <div className="relative mx-2">
              <div
                className="flex justify-between items-center gap-2 border border-black/30 bg-white rounded-lg px-1 py-0.5 cursor-pointer"
                onClick={() => setShowFilter(!showFilter)}
              >
                <img
                  className="h-4"
                  src={Icons.store}
                  alt="icon"
                />
                <div className="flex-1 font-outfit font-semi-bold text-sm text-black/80">
                  {!filter ? 'All' : outlet?.find(item => item.id === filter)?.name}
                </div>
                <img
                  className="h-4"
                  src={Icons.select}
                  alt="icon"
                />
              </div>
              <div
                className={cn(
                  "absolute top-full w-full bg-white border border-black/20 shadow-xl py-2 rounded-xl max-h-80 overflow-auto mt-1",
                  "transition-all duration-300 opacity-0 invisible translate-y-4",
                  showFilter && "opacity-100 visible translate-y-0"
                )}
              >
                <div className="pb-2 px-2">
                  <InputText
                    placeholder="Search location ..."
                    icon={Icons.search_location}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onReset={() => setKeyword('')}
                  />
                </div>
                {!keyword &&
                  <div
                    className={cn(
                      "font-outfit font-semi-bold text-sm p-2 hover:bg-primary-light cursor-pointer",
                      !filter && "bg-primary text-white hover:bg-primary hover:text-white"
                    )}
                    onClick={() => {
                      setFilter(null)
                      setShowFilter(false)
                    }}
                  >
                    All
                  </div>
                }
                {outlet
                  ?.filter(item => keyword ? item.name.toLowerCase().includes(keyword) : item)
                  ?.map((item, index) =>
                    <div
                      key={index}
                      className={cn(
                        "font-outfit font-semi-bold text-sm p-2 hover:bg-primary-light cursor-pointer",
                        filter === item.id && "bg-primary text-white hover:bg-primary hover:text-white"
                      )}
                      onClick={() => {
                        setFilter(item.id)
                        setShowFilter(false)
                        setKeyword('')
                      }}
                    >
                      {item.name}
                    </div>
                  )}
              </div>
            </div>

            {data
              ?.filter(item => filter ? item.store.id === filter : item)
              ?.map((item, index) =>
                <div
                  key={index}
                  className="bg-white/50 border border-black/20 rounded-3xl p-[0.4rem] shadow-sm"
                >
                  <div className="flex justify-between items-center px-2 py-1 mb-1">
                    <div className="flex items-center gap-1.5">
                      <div className="flex justify-center items-center shrink-0 bg-[#fff6f6] bg-opacity-50 border border-[#ffe7e7] rounded-lg p-1 aspect-square">
                        <img
                          className="inline-block h-5 w-5"
                          src={Icons.promo}
                          alt="icon"
                        />
                      </div>
                      <div className="font-outfit font-bold text-sm text-black/70 line-clamp-1">
                        {item.name}
                      </div>
                    </div>
                    <div />
                  </div>
                  <div className="rounded-[1.2rem] border border-black/20 overflow-hidden">

                    <div
                      key={index}
                      className="!block cursor-pointer"
                      onClick={() => navigate(`/promotion/${item?.id}/detail`, { state: { item } })}
                    >
                      <Image item={item} />
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}
