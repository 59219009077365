import { action, computed, thunk } from 'easy-peasy'
import { AxiosError } from 'axios'

import { FetchStatus } from '@/types'
import { CLIENT_CODE } from '@/constants'

import { ClientModel, service } from '.'

export const client: ClientModel = {
  data: [],
  client: null,
  status: FetchStatus.IDLE,
  error: '',

  /** Computed */
  loading: computed(state => state.status === FetchStatus.LOADING),
  loaded: computed(state => state.status === FetchStatus.LOADED),

  /** Action */
  setData: action((state, payload) => {
    state.data = payload
  }),
  setClient: action((state, payload) => {
    state.client = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  /** Thunks */
  fetch: thunk(async (action) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await service.fetch()
      const clients = res.data
      action.setData(clients)
      const client = clients.find(item => item.code === CLIENT_CODE)
      if (client) action.setClient(client)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.ERROR)
      }
    }
  })
}
