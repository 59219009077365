import { cn } from '@/utils'

type Props = {
  collab?: boolean
}

export const Background = ({ collab }: Props) => {
  return (
    <div
      id="background"
      className={cn(
        "fixed top-0 left-1/2 -translate-x-1/2 bg-doodle bg-cover bg-fixed max-w-mobile w-full h-screen -z-10",
        "h-dvh",
        collab && "bg-collab-screen"
      )}
    />
  )
}
