import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Profile, useStoreActions, useStoreState } from '@/stores'
import { cn, getToken, removeCollabToken, removeToken } from '@/utils'
import { AuthStatus } from '@/types'
import { Images } from '@/assets'

type UserContextType = {
  user: Profile | null
  token: string | null
  collab: boolean
  login: (phone: string, password: string) => void
  isLoggedIn: () => boolean
  logout: () => void
}

type Props = { children: ReactNode }

const UserContext = createContext<UserContextType>({} as UserContextType)

export const UserProvider = ({ children }: Props) => {

  const navigate = useNavigate()

  const { login: signin, loggedIn, loggedOut } = useStoreActions(action => action.auth)
  const { auth, collab } = useStoreState(state => state.auth)

  const { fetch: getProfile } = useStoreActions(action => action.profile)
  const { data, unauthenticated } = useStoreState(state => state.profile)

  const [token, setToken] = useState<string | null>(null)
  const [user, setUser] = useState<Profile | null>(null)
  const [isReady, setIsReady] = useState(false)

  const checkToken = useCallback(async () => {
    const token = await getToken()
    if (token) {
      setToken(token)
      loggedIn(token)
    } else {
      setIsReady(true)
    }
  }, [loggedIn])

  useEffect(() => {
    checkToken()
  }, [checkToken])

  useEffect(() => {
    if (data.name) {
      setUser(data)
      setIsReady(true)
    }
  }, [data])

  useEffect(() => {
    if (auth === AuthStatus.AUTHENTICATED) {
      getProfile()
    }
  }, [auth, getProfile])

  const isLoggedIn = () => {
    return !!user
  }

  const login = async (phone: string, password: string) => {
    signin({ phone, password })
  }

  const logout = useCallback(() => {
    loggedOut(() => {
      setUser(null)
      removeToken()
      removeCollabToken()
      navigate('/login')
    })
  }, [loggedOut, navigate])

  useEffect(() => {
    if (unauthenticated) {
      setIsReady(true)
      logout()
    }
  }, [unauthenticated, logout])

  useEffect(() => {
    if (auth === AuthStatus.UNAUTHENTICATED && isReady) {
      logout()
    }
  }, [auth, isReady, logout])

  return (
    <UserContext.Provider
      value={{ user, token, collab, login, isLoggedIn, logout }}
    >
      {isReady ?
        <>{children}</>
        :
        <div
          className={cn(
            "flex flex-col bg-white min-h-screen",
            "min-h-dvh"
          )}
        >
          <div className="flex flex-1 justify-center items-center">
            <div className="font-outfit font-semi-bold text-base">
              <div className="animate-loader">
                <img
                  className="h-28"
                  src={Images.madcoco_head}
                  alt="logo"
                />
                <div className="font-outfit font-semi-bold text-base text-center text-primary mt-2">
                  Loading...
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </UserContext.Provider>
  )

}

export const useAuth = () => useContext(UserContext)
