export interface Paginator {
  current_page: number
  per_page: number
  previous_page: number
  next_page: number
  total_items: number
  total_pages: number
}

export interface PaginationData<T> {
  status_code: number
  status: string
  paginator: Paginator
  data: T[]
}

export interface Data<T> {
  status_code: number
  status: string
  data: T
}

export interface PaginatorParam {
  page: number
  limit: number
}

export interface ClientCodeParam {
  client_code: string
}

export interface ActiveParam {
  active: number
}

export const initialPaginator = {
  current_page: 0,
  next_page: 0,
  per_page: 0,
  previous_page: 0,
  total_items: 0,
  total_pages: 0
}
