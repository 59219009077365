import { Fragment } from 'react'
import { Navigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useAuth } from '@/routes/use_auth'
import { Background, Button, InputPhone, Topbar } from '@/components'
import { useStoreActions } from '@/stores'
import { cn } from '@/utils'

import { useForgotPassword } from './hooks'

export const ForgotPassword = () => {
  const { isLoggedIn } = useAuth()

  const { setTitle, setMessage, present } = useStoreActions(action => action.message)

  const { loading, submit } = useForgotPassword({
    onSuccess: (message: string) => {
      setTitle('RESET PASSWORD')
      setMessage(message)
      present()
    },
    onError: (message: string) => {
      setTitle('ERROR')
      setMessage(message)
      present()
    }
  })

  const ForgotSchema = yup.object().shape({
    phone: yup.string().required('Phone Number is required')
  })

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      phone: ''
    },
    validationSchema: ForgotSchema,
    onSubmit: values => submit(values)
  })

  if (isLoggedIn()) return <Navigate to="/" replace={true} />

  return (
    <Fragment>
      <Background />
      <form onSubmit={handleSubmit}>
        <div
          className={cn(
            "flex flex-col bg-white bg-opacity-30 min-h-screen",
            "min-h-dvh"
          )}
        >
          <Topbar />
          <div className="flex-1 p-5">
            <div className="font-outfit font-bold text-xl text-primary text-left">
              Reset Password
            </div>
            <div
              className="rounded-xl p-2 border border-primary/20 bg-primary-light/50 font-outfit font-semi-bold text-xs text-black/60 mt-3"
            >
              Enter the phone number associated with your account. We'll send the password recovery instruction.
            </div>
            <div className="flex flex-col gap-4 mt-4">
              <InputPhone
                label="Phone number"
                value={values.phone}
                onChange={e => handleChange('phone')(e.target.value.replace(/[+-\s]/g, ''))}
              />
            </div>
          </div>
          <div className="p-5">
            <Button
              type="submit"
              label="Send"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </Fragment>
  )
}