import { collab } from '@/utils'

import { Params, Response, Service } from '.'
import { CLIENT_CODE_COLLAB } from '@/constants'

const fetch = async (params: Params): Promise<Response> => {
  try {
    const res = await collab.get<Response>('customer/vouchers', { params: { ...params, code_client: CLIENT_CODE_COLLAB } })
    return res.data
  } catch (e) {
    throw e
  }
}

export const service: Service = {
  fetch
}
