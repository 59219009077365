export const Icons = {
  back: require('@/assets/icons/back.svg').default,
  calendar: require('@/assets/icons/calendar.svg').default,
  check: require('@/assets/icons/check.svg').default,
  edit_photo: require('@/assets/icons/edit_photo.svg').default,
  empty: require('@/assets/icons/empty.svg').default,
  eye_hide: require('@/assets/icons/eye_hide.svg').default,
  eye_show: require('@/assets/icons/eye_show.svg').default,
  filter: require('@/assets/icons/filter.svg').default,
  gift: require('@/assets/icons/gift.svg').default,
  history: require('@/assets/icons/history.svg').default,
  hogwarts: require('@/assets/icons/hogwarts.svg').default,
  image_break: require('@/assets/icons/image_break.svg').default,
  instagram: require('@/assets/icons/instagram.svg').default,
  key: require('@/assets/icons/key.svg').default,
  loader_green: require('@/assets/icons/loader_green.svg').default,
  loader: require('@/assets/icons/loader.svg').default,
  location: require('@/assets/icons/location.svg').default,
  lock_check: require('@/assets/icons/lock_check.svg').default,
  lock: require('@/assets/icons/lock.svg').default,
  next: require('@/assets/icons/next.svg').default,
  phone: require('@/assets/icons/phone.svg').default,
  prev: require('@/assets/icons/prev.svg').default,
  promo: require('@/assets/icons/promo.svg').default,
  search_location: require('@/assets/icons/search_location.svg').default,
  select: require('@/assets/icons/select.svg').default,
  setting_profile: require('@/assets/icons/setting_profile.svg').default,
  sort_ascending: require('@/assets/icons/sort_ascending.svg').default,
  sort_descending: require('@/assets/icons/sort_descending.svg').default,
  stamp_gryffindor: require('@/assets/icons/stamp_gryffindor.png'),
  stamp_hogwarts: require('@/assets/icons/stamp_hogwarts.png'),
  stamp_hufflepuff: require('@/assets/icons/stamp_hufflepuff.png'),
  stamp_ravenclaw: require('@/assets/icons/stamp_ravenclaw.png'),
  stamp_slytherin: require('@/assets/icons/stamp_slytherin.png'),
  stamp_gryffindor_holder: require('@/assets/icons/stamp_gryffindor_holder.png'),
  stamp_hogwarts_holder: require('@/assets/icons/stamp_hogwarts_holder.png'),
  stamp_hufflepuff_holder: require('@/assets/icons/stamp_hufflepuff_holder.png'),
  stamp_ravenclaw_holder: require('@/assets/icons/stamp_ravenclaw_holder.png'),
  stamp_slytherin_holder: require('@/assets/icons/stamp_slytherin_holder.png'),
  star: require('@/assets/icons/star.svg').default,
  store: require('@/assets/icons/store.svg').default,
  right_arrow: require('@/assets/icons/right_arrow.svg').default,
  tiktok: require('@/assets/icons/tiktok.svg').default,
  user: require('@/assets/icons/user.svg').default,
  voucher: require('@/assets/icons/voucher.svg').default,
  whatsapp: require('@/assets/icons/whatsapp.svg').default
}

export const Images = {
  background_collab: require('@/assets/images/background_collab.png'),
  card_gold: require('@/assets/images/card_gold.jpeg'),
  card_green: require('@/assets/images/card_green.jpeg'),
  card_pink: require('@/assets/images/card_pink.jpeg'),
  card_platinum: require('@/assets/images/card_platinum.jpeg'),
  card_white: require('@/assets/images/card_white.jpg'),
  collab_banner: require('@/assets/images/collab_banner.png'),
  fab: require('@/assets/images/fab.png'),
  hogwarts: require('@/assets/images/hogwarts.png'),
  legal_line: require('@/assets/images/legal_line.png'),
  loyalid_blue: require('@/assets/images/loyalid_blue.svg').default,
  loyalid_white: require('@/assets/images/loyalid_white.svg').default,
  madcoco_head_text: require('@/assets/images/madcoco_head_text.svg').default,
  madcoco_head: require('@/assets/images/madcoco_head.svg').default,
  madcoco_logo_1: require('@/assets/images/madcoco_logo_1.svg').default,
  madcoco_logo_2: require('@/assets/images/madcoco_logo_2.svg').default,
  madcoco_logo_collab: require('@/assets/images/madcoco_logo_collab.png'),
  madcoco: require('@/assets/images/madcoco.png')
}
