import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import { useAuth } from '@/routes/use_auth'
import { Background, Button, Checkbox, InputPassword, InputPhone } from '@/components'
import { Images } from '@/assets'
import { cn, getUserPass, setUserPass } from '@/utils'
import { useStoreState } from '@/stores'
import { FormStatus } from '@/types'

export const Login = () => {
  const navigate = useNavigate()

  const { status } = useStoreState(state => state.auth)
  const { login, isLoggedIn } = useAuth()

  const [phone, setPhone] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [remember, setRemember] = useState<boolean>(false)

  const getUserData = async () => {
    const data = await getUserPass()
    if (data) {
      const { phone, password } = JSON.parse(window.atob(data))
      if (phone && password) {
        setPhone(phone)
        setPassword(password)
        setRemember(true)
      }
    }
  }

  useEffect(() => {
    getUserData()
  }, [])

  if (isLoggedIn()) {
    if (remember) setUserPass(window.btoa(JSON.stringify({ phone, password })))
    return <Navigate to="/" replace={true} />
  }

  return (
    <div>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 p-5 min-h-screen",
          "min-h-dvh"
        )}
      >
        <div className="text-center mt-20">
          <img
            className="inline-block w-4/5 max-w-60 mx-auto"
            src={Images.madcoco_logo_1}
            alt="logo"
          />
        </div>
        <div className="mt-8">
          <InputPhone
            className="mt-3"
            label="Phone number"
            value={phone}
            onChange={e => setPhone(e.target.value.replace(/[+-\s]/g, ''))}
          />
          <InputPassword
            className="mt-3"
            label="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <div className="flex justify-between gap-2 my-4">
            <div className="flex items-center gap-2 pl-1">
              <Checkbox
                id="remember"
                checked={remember}
                onChange={e => setRemember(e.target.checked)}
              />
              <label htmlFor="remember" className="font-outfit font-medium text-base text-black/60 leading-none">
                Remember me
              </label>
            </div>
            <div className="leading-none text-right">
              <span
                className="font-outfit font-semi-bold text-base text-primary leading-none cursor-pointer"
                onClick={() => navigate('/forgot-password')}
              >
                Forgot password?
              </span>
            </div>
          </div>
          <Button
            label="Login"
            disabled={!phone || !password}
            loading={[FormStatus.LOADING, FormStatus.SUCCESS].includes(status)}
            onClick={() => login(phone, password)}
          />
          <div className="font-outfit font-medium text-base text-black/60 text-center mt-3">
            Don't have account?
            <span
              className="font-outfit font-semi-bold text-primary ml-1 cursor-pointer"
              onClick={() => navigate('/register')}
            >
              Register.
            </span>
          </div>
        </div>
        <div className="flex-1" />
        <div className="flex flex-col justify-center items-center my-4">
          <div className="font-outfit font-semi-bold text-xs text-black/60 text-center mt-3">
            Powered by
          </div>
          <img
            className="inline-block h-8 mt-2"
            src={Images.loyalid_blue}
            alt="logo"
          />
        </div>
      </div>
    </div>
  )
}