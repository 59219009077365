import { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'

import { cn } from '@/utils'
import { useAuth } from '@/routes/use_auth'
import { useStoreActions, useStoreState } from '@/stores'
import { Background, Topbar } from '@/components'
import { Icons, Images } from '@/assets'
import { CLIENT_CODE_COLLAB } from '@/constants'

export const History = () => {
  const { collab } = useAuth()

  const { data, loaded, loading, paginator } = useStoreState(state => state.history)
  const { fetch, fetchMore } = useStoreActions(action => action.history)
  const collabState = useStoreState(state => state.collab.history)
  const collabAction = useStoreActions(action => action.collab.history)

  const [filter, setFilter] = useState<'all' | 'point' | 'stamp'>('all')
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [earning, setEarning] = useState<boolean>(true)
  const [redeem, setRedeem] = useState<boolean>(true)

  const limit = 20

  useEffect(() => {
    if (collab) {
      collabAction.fetch({
        page: 1,
        limit
      })
    }
  }, [collab, collabAction])

  useEffect(() => {
    fetch({
      page: 1,
      limit
    })
  }, [fetch])

  const loadMore = useCallback(() => {
    if (loaded && paginator.current_page < paginator.total_pages) {
      fetchMore({
        page: paginator.current_page + 1,
        limit
      })
    }
  }, [fetchMore, paginator, loaded])

  const loadMoreCollab = useCallback(() => {
    const { loaded, paginator } = collabState
    if (loaded && paginator.current_page < paginator.total_pages) {
      collabAction.fetchMore({
        page: paginator.current_page + 1,
        limit
      })
    }
  }, [collabState, collabAction])

  const history = data.concat(collabState.data)

  return (
    <div>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <Topbar />
        <div className="flex flex-col flex-1 p-5">
          <div className="font-outfit font-bold text-xl text-primary text-left">
            History
          </div>
          <div className="flex justify-between items-center flex-wrap mx-1 mt-3 z-10">
            <div className="flex gap-1 mb-1">
              <div
                className={cn(
                  "bg-[#eee] rounded-full px-2.5 py-1",
                  "font-outfit font-medium text-xs text-black/30 text-center",
                  earning && "bg-primary text-white"
                )}
                onClick={() => setEarning(!earning)}
              >
                Earning
              </div>
              <div
                className={cn(
                  "bg-[#eee] rounded-full px-2.5 py-1",
                  "font-outfit font-medium text-xs text-black/30 text-center",
                  redeem && "bg-primary text-white"
                )}
                onClick={() => setRedeem(!redeem)}
              >
                Redeem
              </div>
            </div>
            <div className="relative flex items-center gap-1 bg-white">
              <div
                className="flex items-center border border-black/20 rounded-lg p-0.5 cursor-pointer mb-1"
                onClick={() => setShowFilter(!showFilter)}
              >
                <div className="font-outfit font-semi-bold text-sm text-black/80 text-center w-14 capitalize">
                  {filter}
                </div>
                <img
                  className="h-4"
                  src={Icons.select}
                  alt="icon"
                />
              </div>
              <div
                className={cn(
                  "absolute top-full mt-1 right-0 w-full py-2 rounded-xl bg-white border border-black/20 shadow-lg z-10",
                  "transition-all duration-300 opacity-0 invisible translate-y-4",
                  showFilter && "opacity-100 visible translate-y-0"
                )}
              >
                <div
                  className={cn(
                    "font-outfit font-medium text-sm px-2 py-1 hover:bg-[#f5f5f5] cursor-pointer",
                    filter === 'all' && "bg-primary text-white hover:bg-primary"
                  )}
                  onClick={() => {
                    setShowFilter(false)
                    setFilter('all')
                  }}
                >
                  All
                </div>
                <div
                  className={cn(
                    "font-outfit font-medium text-sm px-2 py-1 hover:bg-[#f5f5f5] cursor-pointer",
                    filter === 'point' && "bg-primary text-white hover:bg-primary"
                  )}
                  onClick={() => {
                    setShowFilter(false)
                    setFilter('point')
                  }}
                >
                  Point
                </div>
                <div
                  className={cn(
                    "font-outfit font-medium text-sm px-2 py-1 hover:bg-[#f5f5f5] cursor-pointer",
                    filter === 'stamp' && "bg-primary text-white hover:bg-primary"
                  )}
                  onClick={() => {
                    setShowFilter(false)
                    setFilter('stamp')
                  }}
                >
                  Stamp
                </div>
              </div>
            </div>
          </div>
          {loading || collabState.loading ?
            <div className="flex flex-1 justify-center items-center">
              <div className="font-outfit font-semi-bold text-base">
                <div className="animate-loader">
                  <img
                    className="h-28"
                    src={Images.madcoco_head}
                    alt="logo"
                  />
                  <div className="font-outfit font-semi-bold text-base text-center text-primary mt-2">
                    Loading...
                  </div>
                </div>
              </div>
            </div>
            :
            history.length === 0 && loaded && collabState.loaded ?
              <div className="flex flex-col flex-1 justify-center items-center">
                <img
                  className="h-12"
                  src={Icons.empty}
                  alt="icon"
                />
                <div className="font-outfit font-medium text-base text-primary">
                  Empty
                </div>
              </div>
              :
              <InfiniteScroll
                dataLength={data?.length}
                next={() => {
                  loadMore()
                  loadMoreCollab()
                }}
                hasMore={paginator?.next_page > 1}
                loader={
                  <div className="flex justify-center items-center p-4">
                    <img
                      className="h-10"
                      src={Icons.loader_green}
                      alt="loader"
                    />
                  </div>
                }
              >
                <div className="flex flex-col gap-1.5 mt-2">
                  {history
                    ?.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf())
                    ?.filter(item => filter === 'point' ? item.client.code !== CLIENT_CODE_COLLAB : filter === 'stamp' ? item.client.code === CLIENT_CODE_COLLAB : item)
                    ?.filter(item => (earning && item.type === 'Earning') || (redeem && item.type === 'Redeem') || (earning && item.type === 'New Registration Reward'))
                    ?.map((item, index) =>
                      <div
                        key={index}
                        className="opacity-0 rounded-xl border border-black/20 bg-white/70 p-2 animate-show"
                        style={{ animationDelay: `${(index > limit ? index - (history.length - limit) : index) * .1}s` }}
                      >
                        <div className="flex items-center gap-2">
                          <div className="inline-flex justify-center items-center bg-white border border-black/10 rounded-lg p-1">
                            <img
                              className="inline-block h-6"
                              src={item?.client?.code === CLIENT_CODE_COLLAB ? Images.hogwarts : Icons.star}
                              alt="icon"
                            />
                          </div>
                          <div className="flex-1">
                            <div className="font-outfit font-regular text-xs leading-tight">
                              {moment(item?.date).format('DD MMM YYYY HH:mm')}
                            </div>
                            <div
                              className={cn(
                                "font-outfit font-semi-bold text-base text-primary leading-tight",
                                item?.client?.code === CLIENT_CODE_COLLAB && "text-[#7a5020]"
                              )}
                            >
                              {item?.type}
                            </div>
                          </div>
                          <div
                            className={cn(
                              "font-outfit font-bold text-base text-primary",
                              item?.client?.code === CLIENT_CODE_COLLAB && "text-[#7a5020]"
                            )}
                          >
                            {item?.point?.redeem?.toLocaleString('id-ID')}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </InfiniteScroll>
          }
        </div>
      </div>
    </div>
  )
}