import Slider, { Settings } from 'react-slick'

import { useAuth } from '@/routes/use_auth'
import { Background, MemberCard, Topbar } from '@/components'
import { cn } from '@/utils'

import { useBenefitCard } from './hooks'
import { Icons, Images } from '@/assets'

export const BenefitCard = () => {
  const { user } = useAuth()

  const { data } = useBenefitCard()

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false
  }

  const cards = data.filter(item => !item?.name?.toLowerCase()?.includes('collab'))

  const currentIndex = cards?.sort((a, b) => a.id - b.id)?.findIndex(item => item.name === user?.card_type?.name)

  return (
    <div>
      <Background />
      <div
        className={cn(
          "flex flex-col bg-white bg-opacity-30 min-h-screen",
          "min-h-dvh"
        )}
      >
        <Topbar />
        <div className="flex flex-col flex-1">
          <div className="bg-primary-light rounded-br-[2rem] rounded-bl-[2rem] border-b border-b-white p-2">
            <MemberCard user={user} />
          </div>
          {cards.length > 0 ?
            <div>
              <div className="p-4">
                <div className="relative">
                  <div className="relative flex justify-between z-10">
                    {cards?.sort((a, b) => a.id - b.id)?.map((item, index) =>
                      <div
                        key={index}
                        className={cn(
                          "rounded-full border border-[#ccc] bg-white p-0.5",
                          index < currentIndex + 1 && "border-primary bg-primary"
                        )}
                      >
                        <div
                          className="flex justify-center items-center w-5 h-5 bg-primary rounded-full border border-black/20 shadow-sm p-[3.5px]"
                          style={{ backgroundColor: item.name.toLowerCase() }}
                        >
                          {index < currentIndex + 1 &&
                            <img
                              className={cn(
                                "h-full invert",
                                item.name.toLowerCase() === 'white' && "invert-0 opacity-50"
                              )}
                              src={Icons.check}
                              alt="icon"
                            />
                          }
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="absolute top-0 left-0 w-full h-full flex justify-between items-center">
                    {[...Array(cards.length - 1)].map((_, index: number) =>
                      <div
                        key={index}
                        className={cn(
                          "flex-1 bg-[#ccc] h-[1px]",
                          index < currentIndex && "h-[3px] bg-primary"
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Slider {...settings}>
                {cards?.sort((a, b) => a.id - b.id)?.map((item, index) =>
                  <div
                    key={index}
                    className="px-4"
                  >
                    <div className="rounded-2xl border border-black/20 shadow-lg overflow-hidden">
                      <img
                        className="w-full"
                        src={item.image}
                        alt="card"
                      />
                    </div>
                    <div className="flex flex-col gap-2 rounded-xl border border-black/20 bg-white bg-opacity-50 p-3 mt-3">
                      {item?.benefits?.map((item, index) =>
                        <div
                          key={index}
                          className="flex gap-2"
                        >
                          <div className="font-outfit font-semi-bold text-xs text-black/80">
                            •
                          </div>
                          <div className="font-outfit font-semi-bold text-xs text-black/80">
                            {item.value}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Slider>
            </div>
            :
            <div className="flex flex-1 justify-center items-center">
              <div className="font-outfit font-semi-bold text-base">
                <div className="animate-loader">
                  <img
                    className="h-28"
                    src={Images.madcoco_head}
                    alt="logo"
                  />
                  <div className="font-outfit font-semi-bold text-base text-center text-primary mt-2">
                    Loading...
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}