import { useNavigate } from 'react-router-dom'

import { Images } from '@/assets'

export const Stamp = () => {
  const navigate = useNavigate()

  return (
    <div className="bg-white/50 border border-black/20 rounded-3xl p-[0.4rem] shadow-sm">
      <div className="flex justify-between items-center p-1 mb-1">
        <div className="flex items-center gap-1.5">
          <div className="flex justify-center items-center bg-[#fff6f6] bg-opacity-50 border border-[#ffe7e7] rounded-lg p-1 aspect-square">
            <img
              className="inline-block h-5 w-5"
              src={Images.hogwarts}
              alt="icon"
            />
          </div>
          <div className="font-outfit font-bold text-sm text-black/70">
            COLLECT STAMPS
          </div>
        </div>
        <div />
      </div>
      <div
        className="rounded-xl border border-black/20 overflow-hidden mb-3 cursor-pointer"
        onClick={() => navigate('/collect-stamps')}
      >
        <img
          className="w-full"
          src={Images.collab_banner}
          alt="banner"
        />
      </div>
    </div>
  )
}
