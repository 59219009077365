import { useState } from 'react'
import { AxiosError } from 'axios'

import { api } from '@/utils'

export interface Props {
  onSuccess: (message: string) => void,
  onError: (message: string) => void
}

export type Params = {
  old_password: string
  new_password: string
}

export const useChangePassword = ({ onSuccess, onError }: Props) => {
  const [loading, setLoading] = useState(false)

  const submit = async (params: Params) => {
    try {
      setLoading(true)
      const res = await api.put('customer/password', { ...params })
      onSuccess(res.data.message)
    } catch (e) {
      if (e instanceof AxiosError) {
        onError(e?.response?.data?.message || 'Network Error')
      }
    } finally {
      setTimeout(() => setLoading(false), 1)
    }
  }

  return { loading, submit }
}
