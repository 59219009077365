import { api } from '@/utils'

import { Response, Service } from '.'

const fetch = async (): Promise<Response> => {
  try {
    const res = await api.get<Response>('customer/profile')
    return res.data
  } catch (e) {
    throw e
  }
}

export const service: Service = {
  fetch
}
