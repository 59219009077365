import { InputHTMLAttributes } from 'react'

import { cn } from '@/utils'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string
}

export const Checkbox = ({ error, ...props }: Props) => {
  return (
    <input
      className={cn(
        "w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary"
      )}
      type="checkbox"
      {...props}
    />
  )
}
