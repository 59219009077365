import { createStore, createTypedHooks, EasyPeasyConfig } from 'easy-peasy'

import { AuthModel, auth } from './auth'
import { ClientModel, client } from './client'
import { CollabModel, collab } from './collab'
import { EventModel, event } from './event'
import { HistoryModel, history } from './history'
import { MessageModel, message } from './message'
import { OutletModel, outlet } from './outlet'
import { ProfileModel, profile } from './profile'
import { PromoModel, promo } from './promo'
import { RedeemModel, redeem } from './redeem'
import { VoucherModel, voucher } from './voucher'

export type { Outlet } from './outlet'
export type { Profile } from './profile'
export type { Promo } from './promo'
export type { RedeemProduct } from './redeem/product'
export type { Voucher } from './voucher/unused'

export interface StoreModel {
  auth: AuthModel
  client: ClientModel
  collab: CollabModel
  event: EventModel
  history: HistoryModel
  message: MessageModel
  outlet: OutletModel
  profile: ProfileModel
  promo: PromoModel
  redeem: RedeemModel
  voucher: VoucherModel
}

const storeModel: StoreModel = {
  auth,
  client,
  collab,
  event,
  history,
  message,
  outlet,
  profile,
  promo,
  redeem,
  voucher
}

const storeConfig: EasyPeasyConfig = {
  devTools: process.env.NODE_ENV !== 'production'
}

const store = createStore(storeModel, storeConfig)
const typedHooks = createTypedHooks<StoreModel>()

export const { useStoreActions, useStoreDispatch, useStoreState } = typedHooks
export default store
