import { action, computed, thunk } from 'easy-peasy'
import { AxiosError } from 'axios'

import { FetchStatus, initialPaginator } from '@/types'

import { ProductModel, service } from '.'

export const product: ProductModel = {
  data: [],
  detail: null,
  status: FetchStatus.IDLE,
  paginator: initialPaginator,
  error: '',

  /** Computed */
  loading: computed(state => state.status === FetchStatus.LOADING),
  loaded: computed(state => state.status === FetchStatus.LOADED),

  /** Action */
  setData: action((state, payload) => {
    state.data = payload
  }),
  addData: action((state, payload) => {
    state.data = [...state.data, ...payload]
  }),
  setDetail: action((state, payload) => {
    state.detail = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setPaginator: action((state, payload) => {
    state.paginator = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  /** Thunks */
  fetch: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await service.fetch(payload)
      action.setPaginator(res.paginator)
      action.setData(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.ERROR)
      }
    }
  }),
  fetchMore: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await service.fetch(payload)
      action.setPaginator(res.paginator)
      action.addData(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.ERROR)
      }
    }
  }),
  fetchDetail: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await service.fetchDetail(payload)
      action.setDetail(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.ERROR)
      }
    }
  }),
}
