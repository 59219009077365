import { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'

import { useAuth } from '@/routes/use_auth'
import { useStoreActions, useStoreState, Voucher } from '@/stores'
import { Icons, Images } from '@/assets'
import { Button } from '@/components'
import { cn } from '@/utils'

type Props = {
  filter?: string
  showBarcode: (data: Voucher, collab?: boolean) => void
}

export const Unused = ({ filter, showBarcode }: Props) => {
  const { collab } = useAuth()

  const { data, loaded, loading, loadingMore, paginator } = useStoreState(state => state.voucher.unused)
  const { fetch, fetchMore } = useStoreActions(action => action.voucher.unused)

  const collabState = useStoreState(state => state.collab.voucher.unused)
  const collabAction = useStoreActions(action => action.collab.voucher.unused)

  const limit = 10

  useEffect(() => {
    if (collab) {
      collabAction.fetch({
        page: 1,
        limit,
        status: 'not-used'
      })
    }
  }, [collab, collabAction])

  useEffect(() => {
    fetch({
      page: 1,
      limit,
      status: 'not-used'
    })
  }, [fetch])

  const loadMore = useCallback(() => {
    if (loaded && paginator.current_page < paginator.total_pages) {
      fetchMore({
        page: paginator.current_page + 1,
        limit,
        status: 'not-used'
      })
    }
  }, [fetchMore, paginator, loaded])

  const loadMoreCollab = useCallback(() => {
    const { loaded, paginator } = collabState
    if (loaded && paginator.current_page < paginator.total_pages) {
      collabAction.fetchMore({
        page: paginator.current_page + 1,
        limit,
        status: 'not-used'
      })
    }
  }, [collabState, collabAction])

  const reward = data.concat(collabState.data)

  const Image = ({ item }: { item: Voucher }) => {
    const [error, setError] = useState<boolean>(false)

    if (error || !item?.product?.images?.[0])
      return (
        <div
          className="flex justify-center items-center aspect-square bg-[#f5f5f5] animate-fade"
        >
          <img
            className="h-5 opacity-20"
            src={Icons.image_break}
            alt="icon"
          />
        </div>
      )

    return (
      <div
        className="aspect-square bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${item?.product?.images?.[0]})` }}
      >
        <img
          className="hidden"
          src={item?.product?.images?.[0]}
          onError={() => setError(true)}
          alt="outlet"
        />
      </div>
    )
  }

  return (
    <>
      {reward.length === 0 && loaded && collabState.loaded ?
        <div className="flex flex-col flex-1 justify-center items-center">
          <img
            className="h-12"
            src={Icons.empty}
            alt="icon"
          />
          <div className="font-outfit font-medium text-base text-primary">
            Empty
          </div>
        </div>
        : loading || collabState.loading ?
          <div className="flex flex-1 justify-center items-center">
            <div className="font-outfit font-semi-bold text-base">
              <div className="animate-loader">
                <img
                  className="h-28"
                  src={Images.madcoco_head}
                  alt="logo"
                />
                <div className="font-outfit font-semi-bold text-base text-center text-primary mt-2">
                  Loading...
                </div>
              </div>
            </div>
          </div>
          :
          <InfiniteScroll
            dataLength={data?.length}
            next={() => {
              loadMore()
              loadMoreCollab()
            }}
            hasMore={paginator?.next_page > 1}
            loader={
              (loadingMore || collabState.loadingMore) &&
              <div className="flex justify-center items-center p-4">
                <img
                  className="h-10"
                  src={Icons.loader_green}
                  alt="loader"
                />
              </div>
            }
          >
            <div className="flex flex-1 flex-col gap-2 mt-2">
              {reward?.filter(item => filter === 'point' ? !item.product.name.includes('Harry Potter') : filter === 'stamp' ? item.product.name.includes('Harry Potter') : item)?.map((item, index) =>
                <div
                  key={index}
                  className="opacity-0 rounded-xl border border-black/20 bg-white bg-opacity-50 shadow-sm p-2 animate-show"
                  style={{ animationDelay: `${(index - (paginator.current_page - 1) * limit) * .1}s` }}
                >
                  <div className="flex gap-2">
                    <div className="w-20 aspect-square shrink-0 rounded-lg overflow-hidden">
                      <Image item={item} />
                    </div>
                    <div>
                      <div className="font-outfit font-semi-bold text-base text-black leading-none">
                        {item?.product?.name}
                      </div>
                      <div className="font-outfit font-regular text-sm text-black leading-none pt-3">
                        Valid until: <span className="font-medium">{moment(item?.expired_at).format('DD MMM YYYY')}</span>
                      </div>
                    </div>
                  </div>
                  <Button
                    className={cn(
                      "rounded-lg mt-3",
                      item?.product?.name?.includes('Harry Potter') && "bg-[#7a5020]"
                    )}
                    labelClassName="text-base"
                    label="Show Code"
                    onClick={() => showBarcode(item, item?.product?.name?.includes('Harry Potter'))}
                  />
                </div>
              )}
            </div>
          </InfiniteScroll>
      }
    </>
  )
}
