import { useNavigate } from 'react-router-dom'
import Sticky from 'react-sticky-el'

import { Icons, Images } from '@/assets'
import { useState } from 'react'
import { cn } from '@/utils'

type Props = {
  backTo?: string
  collab?: boolean
}

export const Topbar = ({ backTo, collab }: Props) => {
  const navigate = useNavigate()

  const [fixed, setFixed] = useState<boolean>(false)

  return (
    <Sticky stickyClassName="z-[100]" onFixedToggle={setFixed}>
      <div
        id="topbar"
        className={cn(
          "relative bg-primary-light p-4",
          collab && "bg-collab",
          fixed && "shadow-md"
        )}
      >
        <div className="absolute top-1/2 -translate-y-1/2">
          <div
            className="inline-flex items-center p-0.5 shadow-sm bg-white rounded-full cursor-pointer"
            onClick={() => backTo ? navigate(backTo) : navigate(-1)}
          >
            <div
              className={cn(
                "bg-primary h-8 aspect-square rounded-full flex justify-center items-center cursor-pointer",
                collab && "bg-[#7a5020]"
              )}
            >
              <img
                className="h-5 invert"
                src={Icons.back}
                alt="icon"
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <img
            className="inline-block h-7 mx-auto"
            src={collab ? Images.madcoco_logo_collab : Images.madcoco_logo_1}
            alt="logo"
          />
        </div>
      </div>
    </Sticky>
  )
}
