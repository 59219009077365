import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getDistance } from 'geolib'

import { Icons } from '@/assets'
import { Outlet as OutletItem, useStoreActions, useStoreState } from '@/stores'

type CurrentLocation = {
  latitude: number
  longitude: number
}

interface Props {
  currentLocation: CurrentLocation
}

export const Outlet = ({ currentLocation }: Props) => {
  const navigate = useNavigate()

  const { data } = useStoreState(state => state.outlet)
  const { fetch } = useStoreActions(action => action.outlet)

  useEffect(() => {
    fetch({
      page: 1,
      limit: 200,
      active: 1
    })
  }, [fetch])

  const calculateDistance = (from: OutletItem, to: CurrentLocation) => {
    if (!to) return 0
    const dis = getDistance(
      { latitude: from.latitude, longitude: from.longitude },
      { latitude: to.latitude, longitude: to.longitude }
    )
    return dis / 1000
  }

  const { latitude, longitude } = currentLocation
  if (latitude !== 0 && longitude !== 0) data.sort((a, b) => calculateDistance(a, currentLocation) - calculateDistance(b, currentLocation))

  const Image = ({ item }: { item: OutletItem }) => {
    const [error, setError] = useState<boolean>(false)

    if (error || !item?.image)
      return (
        <div
          className="flex justify-center items-center aspect-square bg-[#f5f5f5] animate-fade"
        >
          <img
            className="h-10 opacity-20"
            src={Icons.image_break}
            alt="icon"
          />
        </div>
      )

    return (
      <div
        className="aspect-square bg-[center_top] bg-cover"
        style={{ backgroundImage: `url(${item?.image})` }}
      >
        <img
          className="hidden"
          src={item?.image}
          onError={() => setError(true)}
          alt="outlet"
        />
      </div>
    )
  }

  return (
    <div className="bg-white/50 border border-black/20 rounded-3xl p-[0.4rem] shadow-sm">
      <div className="flex justify-between items-center px-2 py-1 mb-1">
        <div className="flex items-center gap-1.5">
          <div className="flex justify-center items-center bg-[#fff6f6] bg-opacity-50 border border-[#ffe7e7] rounded-lg p-1 aspect-square">
            <img
              className="inline-block h-5 w-5"
              src={Icons.store}
              alt="icon"
            />
          </div>
          <div className="font-outfit font-bold text-sm text-black/70">
            OUTLETS
          </div>
        </div>
        <div
          className="flex items-center gap-1.5 cursor-pointer"
          onClick={() => navigate('/outlet')}
        >
          <div className="font-outfit font-bold text-sm text-primary">
            See All
          </div>
          <img
            className="block h-4"
            src={Icons.right_arrow}
            alt="icon"
          />
        </div>
      </div>
      {data?.length > 0 &&
        <div className="flex gap-[.4rem] p-[0_.3rem_1rem] m-[0_-.4rem] overflow-auto">
          {data.slice(0, 10).map((item, index) =>
            <div
              key={index}
              className="flex flex-col w-[calc(-.85rem+33.3vw)] max-w-[8.5rem] shrink-0 rounded-lg bg-white bg-opacity-50 border border-[#dddddd] overflow-hidden cursor-pointer"
              onClick={() => navigate(`/outlet/${item?.id}/detail`, { state: { item } })}
            >
              <Image item={item} />
              <div className="flex flex-1 flex-col justify-center items-center px-1 py-2">
                <div className="font-outfit font-bold text-xs text-black/70 text-center leading-[1.2] line-clamp-2">
                  {item?.name}
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  )
}
