import { Fragment } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Background, Button, InputPassword, Topbar } from '@/components'
import { Icons } from '@/assets'
import { cn } from '@/utils'

import { useChangePassword } from './hooks'
import { useStoreActions } from '@/stores'
import { useNavigate } from 'react-router-dom'

export const ChangePassword = () => {
  const navigate = useNavigate()

  const { setTitle, setMessage, present } = useStoreActions(action => action.message)

  const { loading, submit } = useChangePassword({
    onSuccess: (message: string) => {
      setTitle('SUCCESS')
      setMessage(message)
      present()
      navigate(-1)
    },
    onError: (message: string) => {
      setTitle('ERROR')
      setMessage(message)
      present()
    }
  })

  const schema = yup.object().shape({
    old_password: yup.string().required('Old password is required'),
    new_password: yup
      .string()
      .required('New password is required')
      .matches(/^(?=[^A-Za-z]*[A-Za-z])(?=.*\d).{8,}$/, 'The password must be at least 8 characters, a combination of letters and numbers'),
    confirm_password: yup
      .string()
      .required('Confirm new password is required')
      .matches(/^(?=[^A-Za-z]*[A-Za-z])(?=.*\d).{8,}$/, 'The password must be at least 8 characters, a combination of letters and numbers')
      .oneOf([yup.ref('new_password')], 'Passwords must match')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_password: ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: schema,
    onSubmit: values => submit({ old_password: values.old_password, new_password: values.new_password })
  })

  return (
    <Fragment>
      <Background />
      <form onSubmit={handleSubmit}>
        <div
          className={cn(
            "flex flex-col bg-white bg-opacity-30 min-h-screen",
            "min-h-dvh"
          )}
        >
          <Topbar />
          <div className="flex-1 p-5">
            <div className="font-outfit font-bold text-xl text-primary text-left">
              Change Password
            </div>
            <div className="flex flex-col gap-4 mt-4">
              <InputPassword
                name="old_password"
                label="Old password"
                value={values.old_password}
                onChange={handleChange}
                error={errors.old_password}
              />
              <InputPassword
                name="new_password"
                label="New password"
                value={values.new_password}
                onChange={handleChange}
                error={errors.new_password}
              />
              <InputPassword
                name="confirm_password"
                label="Confirm new password"
                icon={Icons.lock_check}
                value={values.confirm_password}
                onChange={handleChange}
                error={errors.confirm_password}
              />
            </div>
          </div>
          <div className="p-5">
            <Button
              type="submit"
              label="Save"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </Fragment>
  )
}
