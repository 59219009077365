import { InputHTMLAttributes, useState } from 'react'

import { cn } from '@/utils'
import { Icons } from '@/assets'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: string
  icon?: string
  error?: string
}

export const InputPassword = ({ className, label, icon, error, ...props }: Props) => {

  const [show, setShow] = useState<boolean>(false)

  return (
    <div className={cn("relative", className)}>
      {label &&
        <div className="font-outfit font-medium text-base text-black/80 text-left mb-1">
          {label}
        </div>
      }
      <div className="relative">
        <input
          className={cn(
            "w-full bg-white rounded-lg border border-[#ececfb] outline-none p-1.5",
            "focus:bg-[#f9f9f9] focus:border-primary focus:ring-1 focus:ring-primary",
            "font-outfit font-semi-bold text-base text-black/70 placeholder:font-medium",
            error && "ring-red-600 border-red-400"
          )}
          type={show ? 'text' : 'password'}
          {...props}
        />
        <div className="absolute top-0 right-0 h-full p-1">
          <div
            className="h-full flex justify-center items-center aspect-square rounded-md cursor-pointer"
            onClick={() => setShow(!show)}
          >
            <img
              className="inline-block h-5 transition-all duration-300 opacity-70"
              src={show ? Icons.eye_show : Icons.eye_hide}
              alt="icon"
            />
          </div>
        </div>
      </div>
      {error &&
        <div className="font-outfit font-medium text-xs text-red-600 mt-1">
          * {error}
        </div>
      }
    </div>
  )
}
