import { action, thunk, thunkOn } from 'easy-peasy'

import { MessageModel } from '.'

export const message: MessageModel = {
  type: 'info',
  title: 'ERROR',
  message: '',
  open: false,
  retry: false,

  setType: action((state, payload) => {
    state.type = payload
  }),
  setTitle: action((state, payload) => {
    state.title = payload
  }),
  setMessage: action((state, payload) => {
    state.message = payload
  }),
  setOpen: action((state, payload) => {
    state.open = payload
  }),
  setRetry: action((state, payload) => {
    state.retry = payload
  }),

  present: thunk((action) => {
    action.setOpen(true)
  }),
  close: thunk((action) => {
    action.setOpen(false)
  }),
  onCancel: thunk((action, callback) => {
    action.setOpen(false)
    callback()
  }),
  onConfirm: thunk((action, callback) => {
    action.setOpen(false)
    callback()
  }),
  onRetry: thunk((action, callback) => {
    action.setRetry(true)
    action.setRetry(false)
    callback()
  }),

  onError: thunkOn(
    (_, storeAction) => [
      storeAction.auth.setError,
      storeAction.client.setError,
      storeAction.event.setError,
      storeAction.history.setError,
      storeAction.outlet.setError,
      storeAction.profile.setError,
      storeAction.promo.setError,
      storeAction.redeem.product.setError,
      storeAction.redeem.voucher.setError,
      storeAction.voucher.unused.setError,
      storeAction.voucher.used.setError,
      storeAction.collab.history.setError,
      storeAction.collab.product.setError,
      storeAction.collab.profile.setError,
      storeAction.collab.redeem.setError,
      storeAction.collab.voucher.unused.setError,
      storeAction.collab.voucher.used.setError
    ],
    (action, target) => {
      if (target.payload) {
        action.setType('error')
        action.setTitle('ERROR')
        action.setMessage(target.payload)
        action.present()
      } else {
        action.setOpen(false)
      }
    }
  )
}
