import { ChangeEvent, Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Compressor from 'compressorjs'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useAuth } from '@/routes/use_auth'
import { Background, Button, Checkbox, InputDate, InputPhone, InputText, Topbar } from '@/components'
import { useStoreActions } from '@/stores'
import { Icons, Images } from '@/assets'
import { cn } from '@/utils'

import { useEditProfile } from './hooks'

export const EditProfile = () => {
  const navigate = useNavigate()

  const { user } = useAuth()

  const [photo, setPhoto] = useState<File>()
  const [preview, setPreview] = useState<string>(user?.profile_picture || '')

  const { setTitle, setMessage, present } = useStoreActions(action => action.message)

  const { loading, submit } = useEditProfile({
    onSuccess: (message: string) => {
      setTitle('SUCCESS')
      setMessage(message)
      present()
      navigate(-1)
    },
    onError: (message: string) => {
      setTitle('ERROR')
      setMessage(message)
      present()
    }
  })

  const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    mobile_phone: yup.string().required('Phone number is required'),
    gender: yup.string().required('Gender is required'),
    birth_date: yup.string().required('Birth date is required')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      name: user?.name || '',
      mobile_phone: user?.phone?.mobile || '',
      gender: user?.gender || '',
      birth_date: user?.birth_date || ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: schema,
    onSubmit: values => submit({ ...values, profile_picture: photo })
  })

  const upload = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files as FileList
    setPhoto(files?.[0])
    new Compressor(files?.[0], {
      maxWidth: 1000,
      minHeight: 1000,
      success: res => setPhoto(new File([res], `${new Date().getTime()}.${files[0].type.replace(/(.*)\//g, '')}`)),
      error: err => console.log(err)
    })
    setPreview(URL.createObjectURL(files?.[0]))
  }

  return (
    <Fragment>
      <Background />
      <form onSubmit={handleSubmit}>
        <div
          className={cn(
            "flex flex-col bg-white bg-opacity-30 min-h-screen",
            "min-h-dvh"
          )}
        >
          <Topbar />
          <div className="flex-1 p-5">
            <div className="font-outfit font-bold text-xl text-primary text-left">
              Edit Profile
            </div>
            <div className="p-4">
              <div>
                <label
                  className={cn(
                    "relative  flex justify-center items-center my-1 mx-auto w-32 h-32 rounded-full border border-[#eeeeee] bg-white bg-cover bg-center shadow-lg"
                  )}
                  style={{ backgroundImage: `url(${preview}` }}
                >
                  {!preview &&
                    <img
                      className="h-20"
                      src={Images.madcoco_head_text}
                      alt="logo"
                    />
                  }
                  <div className="absolute bottom-0 right-0 rounded-lg bg-white border border-black/10 p-1 shadow-lg z-10">
                    <img
                      className="h-5"
                      src={Icons.edit_photo}
                      alt="icon"
                    />
                  </div>
                  <input
                    className="hidden"
                    type="file"
                    accept=".jpg,.jpeg,.png|image/*;capture=camera"
                    onChange={upload}
                  />
                </label>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-4">
              <InputText
                name="name"
                label="Name"
                icon={Icons.user}
                value={values.name}
                onChange={handleChange}
                error={errors.name}
              />
              <InputPhone
                name="mobile_phone"
                label="Phone number"
                value={values.mobile_phone}
                onChange={e => handleChange('mobile_phone')(e.target.value.replace(/[+-\s]/g, ''))}
                error={errors.mobile_phone}
                disabled
              />
              <InputDate
                name="birth_date"
                label="Birth Date"
                value={values.birth_date}
                onChange={handleChange}
                error={errors.birth_date}
              />
              <div className="flex gap-2 p-1">
                <div className="flex basis-1/2 items-center gap-2">
                  <Checkbox
                    id="male"
                    defaultChecked={values.gender === 'M'}
                    checked={values.gender === 'M'}
                    onClick={() => handleChange('gender')('M')}
                  />
                  <label
                    className={cn(
                      "font-outfit font-medium text-base text-black/80 leading-none",
                      values.gender === 'M' && "text-black/80 font-semi-bold"
                    )}
                    htmlFor="male"
                  >
                    Male
                  </label>
                </div>
                <div className="flex basis-1/2 items-center gap-2">
                  <Checkbox
                    id="female"
                    defaultChecked={values.gender === 'F'}
                    checked={values.gender === 'F'}
                    onClick={() => handleChange('gender')('F')}
                  />
                  <label
                    className={cn(
                      "font-outfit font-medium text-base text-black/80 leading-none",
                      values.gender === 'F' && "text-black/80 font-semi-bold"
                    )}
                    htmlFor="female"
                  >
                    Female
                  </label>
                </div>
              </div>
              {errors.gender &&
                <div className="font-outfit font-medium text-xs text-red-600 -mt-3">
                  * {errors.gender}
                </div>
              }
            </div>
          </div>
          <div className="p-5">
            <Button
              type="submit"
              label="Save"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </Fragment>
  )
}
