import { ProductModel, product } from './product'
import { VoucherModel, voucher } from './voucher'

export interface RedeemModel {
  product: ProductModel
  voucher: VoucherModel
}

export const redeem: RedeemModel = {
  product,
  voucher
}
